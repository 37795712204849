import React, { ReactElement } from 'react';
import PageTitle from 'components/PageTitle';
import DividerWithSpacing from 'components/DividerWithSpacing';
import Evaporation from 'sections/Evaporation';
import Chemicals from 'sections/Chemicals';
import SlurryTitration from 'sections/SlurryTitration';
import Process from 'sections/Process';
import IonExchangeData from 'sections/IonExchangeData';
import { Box } from '@material-ui/core';
import MarketSelector from 'components/dialogs/MarketSelector';

const IonExchange = (): ReactElement => {
  return (
    <>
      <PageTitle title="2.  Plant capacity, liquefaction and Ion exchange process conditions" />
      <MarketSelector />
      <Process />
      <DividerWithSpacing />
      <Chemicals />
      <DividerWithSpacing />
      <SlurryTitration />
      <DividerWithSpacing />
      <Evaporation />
      <DividerWithSpacing />
      <IonExchangeData />
      <Box my={6} />
    </>
  );
};

export default IonExchange;
