import React, { ReactElement, useMemo } from 'react';
import { LineChart, XAxis, YAxis, Line, CartesianGrid } from 'recharts';

export type DEChartEntry = {
  time: number;
  modelA: number;
  modelB: number;
};

export interface DEChartProps {
  modelAName?: string;
  modelAColor: string;
  modelBName?: string;
  modelBColor: string;
  data: DEChartEntry[];
  useRef?: (ref: any) => void;
}

const DEChart = ({
  data,
  modelAName,
  modelBName,
  modelAColor,
  modelBColor,
  useRef,
  ...restProps
}: DEChartProps): ReactElement => {
  const yAxisTicks = useMemo(() => {
    const lastDataPoint = data[data.length - 1];
    if (!lastDataPoint) {
      return undefined;
    }
    lastDataPoint.modelA = lastDataPoint.modelA || 0;
    lastDataPoint.modelB = lastDataPoint.modelB || 0;
    const arrayLen = Math.round(Math.max(lastDataPoint.modelA, lastDataPoint.modelB)) + 1 || 0;

    return Array.from(Array(arrayLen).keys());
  }, [data]);

  return (
    <LineChart data={data} ref={useRef} {...restProps}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="time"
        label={{
          value: 'Liquefaction time (min)',
          position: 'center',
          dy: 10,
          fill: 'rgba(0, 0, 0, 0.47)',
          fontSize: 16,
          transform: 'translate(0,20)',
        }}
        height={80}
        tick={{ dy: 10 }}
        tickSize={12}
        tickLine={{ stroke: '#cccccc' }}
      />
      <YAxis
        tick={{ dx: -10 }}
        ticks={yAxisTicks}
        width={100}
        label={{
          value: 'DE%(fehling)',
          transform: 'rotate(-90),translate(-200,-120)',
          fill: 'rgba(0, 0, 0, 0.47)',
          fontSize: 16,
        }}
        tickSize={12}
        tickLine={{ stroke: '#cccccc' }}
      />
      <Line strokeWidth={4} dot={false} type="monotone" dataKey="modelA" stroke={modelAColor} name={modelAName} />
      <Line strokeWidth={4} dot={false} type="monotone" dataKey="modelB" stroke={modelBColor} name={modelBName} />
    </LineChart>
  );
};

export default DEChart;
