import { Dialog, Typography, DialogTitle, DialogContent, Box, DialogActions } from '@material-ui/core';
import React, { useState, useCallback, useEffect, ReactElement, ChangeEvent } from 'react';
import { Region } from 'scenarios/ScenarioType';
import { useRecoilState } from 'recoil';
import { Button, SelectWithLabel } from '@novozymes/components';
import { useHistory } from 'react-router-dom';
import { activeScenarioRegion } from 'state/getDerivedValues';

const MarketSelector = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const [activeRegion, setActiveRegion] = useRecoilState(activeScenarioRegion);
  const [region, setRegion] = useState<string>('');
  const [error, setError] = useState<string>('');

  const history = useHistory();

  const handleBack = useCallback(() => {
    setOpen(false);
    history.push('/');
  }, []);

  const handleContinue = useCallback(() => {
    if (region) {
      setOpen(false);
      setError('');
      const parsedRegion = Region[region as keyof typeof Region];
      setActiveRegion(parsedRegion);
    } else {
      setError('Please select a region');
    }
  }, [activeRegion, region]);

  useEffect(() => {
    if (!activeRegion) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeRegion]);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown onClose={handleBack} open={open}>
      <DialogTitle disableTypography={false}>
        <Box mt={2} mx={3}>
          <Typography variant="body1">In what region is your plant located?</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mx={2}>
          <SelectWithLabel
            value={region}
            options={Object.entries(Region).map(([key, regionOption]) => {
              return { value: key, label: regionOption };
            })}
            placeholder="Select a region"
            handleChange={(
              event: ChangeEvent<{
                name?: string | undefined;
                value: string;
              }>
            ): void => {
              setRegion(event.target.value);
            }}
            error={!!error}
            errorMessage={error}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={2} mt={4} mr={2}>
          <Button id="btn-market-select-back" type="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button id="btn-market-select-continue" type="primary" onClick={handleContinue}>
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default MarketSelector;
