import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@novozymes/components';
import { HEADER_HEIGHT, PROCESS_HEADER_HEIGHT } from 'components/Constants';
import React, { ReactElement, useCallback } from 'react';
import navigateHome from 'utils/navigateHome';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
  },
  errorHeading: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: 'inherit',
  },
  errorText: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: 'inherit',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT} - ${PROCESS_HEADER_HEIGHT}) `,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const ErrorPage = (): ReactElement => {
  const classes = useStyles();

  const handleGoHome = useCallback(() => {
    navigateHome();
  }, []);

  return (
    <>
      <Grid className={classes.content} container justify="center">
        <Box className={classes.container}>
          <Box mb={10} />
          <Typography className={classes.errorHeading}>Woops!</Typography>
          <Box mb={4} />
          <Typography className={classes.errorText}>
            We weren’t able to load your scenario for some reason. <br /> Please go back and try again!
          </Typography>
          <Box mb={6} />
          <Button type="primary" small onClick={handleGoHome}>
            Back to home page
          </Button>
          <Box mb={10} />
          <img alt="Error" src="/error_icon.svg" />
        </Box>
      </Grid>
    </>
  );
};

export default ErrorPage;
