import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { scenarioErrorState } from 'state/atomState';
import { Box, makeStyles, Theme, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@novozymes/components';
import zIndexes from 'theme/zIndexes';

const useStyle = makeStyles((theme: Theme) => ({
  errorWrapper: {
    zIndex: zIndexes.overNineThousand,
    position: 'absolute',
    width: '100vw',
    top: 0,
    height: '48px',
    backgroundColor: colors.errorRed,
  },
  errorContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errorText: {
    fontSize: '16px',
    color: theme.palette.common.white,
  },
  closeButton: {
    alignSelf: 'flex-end',
    color: theme.palette.common.white,
  },
}));

const ErrorBanner = (): ReactElement | null => {
  const errors = useRecoilValue(scenarioErrorState);
  const classes = useStyle();

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [errors]);

  const handleCloseClick = useCallback(() => {
    setShowBanner(false);
  }, []);
  return (
    <Slide direction="down" in={showBanner} mountOnEnter unmountOnExit>
      <Box className={classes.errorWrapper}>
        <Box px={6} className={classes.errorContent}>
          <Box />
          <Box className={classes.errorText}>One or more values are missing.</Box>
          <Box>
            <IconButton aria-label="close" onClick={handleCloseClick} color="inherit" className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

export default ErrorBanner;
