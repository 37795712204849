import { EnzymeModel } from './EnzymeModel';
import { EnzymeInput } from './EnzymeInput';
import DEMethods from './DEMethods';
import isEnzymeInputValid from './EnzymeInputValidator';

const SuhongAAPlus2H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (-115.8593446 +
        -3.449826698 * input.dosage +
        -75.52525253 * input.dosage ** 2 +
        0.357734205 * input.dosage * time +
        40.47867637 * input.pH +
        -3.743232323 * input.pH ** 2 +
        0.046122004 * input.pH * time +
        9.861111111 * input.pH * input.dosage +
        0.007423401 * input.calcium +
        -0.000270076 * input.calcium ** 2 +
        (-0.03808067 * time ** 2) / 100 +
        -0.189286715 * time)
  );
};

const SuhongAAPlus4H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (-115.8593446 +
        -3.449826698 * input.dosage +
        -75.52525253 * input.dosage ** 2 +
        0.357734205 * input.dosage * time +
        40.47867637 * input.pH +
        -3.743232323 * input.pH ** 2 +
        0.046122004 * input.pH * time +
        9.861111111 * input.pH * input.dosage +
        0.007423401 * input.calcium +
        -0.000270076 * input.calcium ** 2 +
        (-0.03808067 * time ** 2) / 100 +
        -0.189286715 * time)
  );
};

const SuhongAAPlus: EnzymeModel = (input: EnzymeInput): number[] => {
  const simulation: number[] = Array(9).fill(0);

  if (!isEnzymeInputValid(input)) {
    return simulation;
  }

  const model = input.timeStep === 15 ? SuhongAAPlus2H : SuhongAAPlus4H;

  for (let timeI = 0; timeI < simulation.length; timeI += 1) {
    const time = timeI * input.timeStep;

    simulation[timeI] = model(input, time);
  }

  if (input.timeStep === 30) {
    simulation[5] = simulation[4] + (simulation[4] - simulation[3]) ** 2 / (simulation[3] - simulation[2]);
    simulation[6] = simulation[5] + (simulation[5] - simulation[4]) ** 2 / (simulation[4] - simulation[3]);
    simulation[7] = simulation[6] + (simulation[6] - simulation[5]) ** 2 / (simulation[5] - simulation[4]);
    simulation[8] = simulation[7] + (simulation[7] - simulation[6]) ** 2 / (simulation[6] - simulation[5]);
  }

  return simulation;
};

export default SuhongAAPlus;
