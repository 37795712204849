import React, { ReactElement } from 'react';
import EnzymeSection from 'sections/Enzymes';
import PageTitle from 'components/PageTitle';
import { Box } from '@material-ui/core';
import MarketSelector from 'components/dialogs/MarketSelector';

function SetReference(): ReactElement {
  return (
    <>
      <PageTitle title="1. Set your reference case" subTitle="Compare a new enzyme with your current solution. " />
      <MarketSelector />
      <EnzymeSection />
      <Box my={6} />
    </>
  );
}

export default SetReference;
