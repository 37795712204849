import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { ResponsiveContainer } from 'recharts';
import { Box, makeStyles, Grid, useTheme, Typography, Fade } from '@material-ui/core';
import { colors } from '@novozymes/components';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import getAtomState from 'state/atomState';
import {
  phAdjustNewEnzyme,
  refEnzymeModelOutput,
  availableEnyzmes,
  enzymeRanges,
  isEnzymesOutOfRangeState,
} from 'state/getDerivedValues';
import { EnzymeInput } from 'calculation/enzymeModels/EnzymeInput';
import fitEnymeDosage from 'calculation/utils/fitEnzymeDosage';
import roundNumber from 'utils/roundNumber';
import { EnzymeModel } from 'calculation/enzymeModels/EnzymeModel';
import { TimeStep } from 'scenarios/ScenarioType';
import zIndexes from 'theme/zIndexes';
import DEChart from './DEChart';

export type DEChartEntry = {
  time: number;
  modelA: number;
  modelB: number;
};

export interface DEChartWrapperProps {
  modelAName?: string;
  modelBName?: string;
  data: DEChartEntry[];
  height: number;
}

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  legendContainer: {
    width: '100%',
    background: '#F6F7F5',
    padding: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  experimentParams: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  disclaimer: {
    marginTop: theme.spacing(1),
    fontSize: '10px',
    lineHeight: '12px',
  },
  fitButton: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
  },
  fitError: {
    color: colors.errorRed,
  },
  chartWrapper: {
    position: 'relative',
  },
  outOfRangeErrorBox: {
    position: 'absolute',
    zIndex: zIndexes.medium,
    marginLeft: '106px',
    marginTop: '6px',
    padding: '24px',
    width: 'calc(100% - 112px)',
    backgroundColor: '#F6F7F5',
  },
  outOfRangeHeading: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: '12px',
  },
  outOfRangeBody: { fontSize: '16px', lineHeight: '24px' },
}));

type LegendEntryProps = {
  name?: string;
  color: string;
};

const legendStyles = makeStyles((theme) => ({
  legendIcon: {
    width: '16px',
    height: '4px',
    marginRight: theme.spacing(1),
    background: (props: LegendEntryProps): string =>
      props.color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
  },
  legendWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const LegendEntry = (props: LegendEntryProps): ReactElement => {
  const { name } = props;

  const classes = legendStyles(props);

  return (
    <Grid item xs={12} container alignItems="center" className={classes.legendWrapper}>
      <Grid item container justify="center" xs={3}>
        <Box className={classes.legendIcon} />
      </Grid>
      <Grid item xs={9}>
        {name}
      </Grid>
    </Grid>
  );
};

const DEChartWrapper = ({ data, modelAName, modelBName, height }: DEChartWrapperProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();

  //
  // For now decided to not go with 4h version - leaving it here just in case
  //
  // const [timeStep, timeStepInput] = useInputWithState<TimeStep>({
  //   key: 'timeStep',
  //   type: 'Radio',
  //   label: 'DE Model',
  //   selectOptions: [
  //     { label: '2 hours', value: '15' },
  //     { label: '4 hours', value: '30' },
  //   ],
  // });

  const timeStep = useRecoilValue(getAtomState<TimeStep>('timeStep'));

  const models = useRecoilValue(availableEnyzmes);
  const setNewEnzymeDosage = useSetRecoilState(getAtomState('newEnzymeDosage'));
  const newEnzPhAdj = useRecoilValue(phAdjustNewEnzyme);
  const refModelPlotData = useRecoilValue(refEnzymeModelOutput);

  const newEnzymeName = useRecoilValue(getAtomState<string>('newEnzymeName'));
  const newEnzymepH = useRecoilValue(getAtomState<number>('newEnzymepH'));
  const newEnzymeCalcium = useRecoilValue(getAtomState<number>('newEnzymeCalcium'));

  const [fitEnzymeError, setFitEnzymeError] = useState('');

  const isOutOfRange = useRecoilValue(isEnzymesOutOfRangeState);

  const [showOutOfRange, setShowOutOfRange] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowOutOfRange(false);

    if (isOutOfRange) {
      const newTimer = setTimeout(() => {
        setShowOutOfRange(isOutOfRange);
      }, 2000);
      setTimer(newTimer);
    }
  }, [isOutOfRange, setShowOutOfRange]);

  const ENZYME_RANGES = useRecoilValue(enzymeRanges);

  const fitNewEnzyme = useCallback(() => {
    setFitEnzymeError('');
    if (!newEnzymeName || !timeStep || !newEnzymepH || !newEnzymeCalcium) {
      setFitEnzymeError('Please enter all values for the new enzyme');
      return undefined;
    }

    const getEnzymeDE = (dosage: number): number | undefined => {
      const model = models[newEnzymeName];

      const ranges = ENZYME_RANGES[newEnzymeName];
      const maxCalcium = ranges && ranges.calcium.max;

      const totalCalcium = newEnzymeCalcium + newEnzPhAdj;

      const inputs: EnzymeInput = {
        DEMethod: 'Fehling',
        dosage,
        pH: newEnzymepH,
        calcium: maxCalcium && totalCalcium >= maxCalcium ? maxCalcium : totalCalcium,
        timeStep,
      };
      const modelResult = (model as EnzymeModel)(inputs);

      if (!modelResult.length) {
        return undefined;
      }

      return modelResult[modelResult.length - 1];
    };

    if (!refModelPlotData.length) {
      setFitEnzymeError("Can't get reference model results");
      return;
    }

    const newDosage = fitEnymeDosage({
      targetDE: refModelPlotData[refModelPlotData.length - 1],
      enzymeDosageRange: ENZYME_RANGES[newEnzymeName].dosage,
      getEnzymeDE,
    });
    if (!newDosage) {
      setFitEnzymeError("Can't find a matching dosage for the selected inputs");
      return;
    }
    setNewEnzymeDosage(roundNumber(newDosage, 4));
  }, [newEnzymeName, timeStep, newEnzymepH, newEnzymeCalcium, refModelPlotData, ENZYME_RANGES]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} sm={2} />
      <Grid item xs={11} sm={10} className={classes.heading}>
        DE Development, new enzyme vs. reference
      </Grid>

      <Grid item xs={9} justify="center">
        <Box className={classes.chartWrapper}>
          <Fade in={showOutOfRange}>
            <Box className={classes.outOfRangeErrorBox}>
              <Typography className={classes.outOfRangeHeading}>Values out of range</Typography>
              <Typography className={classes.outOfRangeBody}>
                A value you’ve provided is outside allowed range, making this graph temporarily misleading.
              </Typography>
            </Box>
          </Fade>
          <ResponsiveContainer height={height}>
            <DEChart
              data={data}
              modelAName={modelAName}
              modelBName={modelBName}
              modelAColor={theme.palette.primary.main}
              modelBColor={theme.palette.secondary.main}
            />
          </ResponsiveContainer>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.legendContainer}>
          <LegendEntry name={modelAName && `1. ${modelAName}`} color="primary" />
          <LegendEntry name={modelBName && `2. ${modelBName}`} color="secondary" />
        </Box>
        {/* <Box mt={3}>{timeStepInput}</Box> */}
        <Box mt={3}>
          <Box id="btn-fit-dosage" className={classes.fitButton} onClick={fitNewEnzyme}>
            Fit enzyme dosage
          </Box>
          <Box mt={1} className={classes.fitError}>
            {fitEnzymeError}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.experimentParams}>Primary liquefaction: jet cooking at 105 °C for 5 minutes.</Typography>
        <Typography className={classes.experimentParams}>Secondary liquefaction: 95°C/203F </Typography>
        <Typography className={classes.experimentParams}>DS: 35% initial DS</Typography>
        <Typography className={classes.experimentParams}>Conductivity 500 μS/cm</Typography>
        <Typography className={classes.disclaimer}>
          The information contained in this tool is provided solely as guidance. Novozymes makes no guarantees regarding the
          information&apos;s accuracy, fitness for a particular purpose, non-infringement of intellectual property rights or
          regulatory/legal compliance.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DEChartWrapper;
