import React, { ReactElement } from 'react';
import { Grid, Typography, Box, makeStyles, Theme } from '@material-ui/core';
import ValueHighlight from 'components/ValueHighlight';
import { useRecoilValue } from 'recoil';

import {
  ixChemicalSavings,
  liqEnzymeSavings,
  liqCalciumSavings,
  liqBaseSavings,
  liqAcidSavings,
  ionExchangeResinSavings,
  ionExchWasteWaterSavings,
  ionExchWaterSavings,
  ionExchSweetWaterSavings,
  currency,
} from 'state/getDerivedValues';

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: '16px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    fontSize: '16px',
  },
}));

const SavingsDetail = (): ReactElement => {
  const calciumSavings = useRecoilValue(liqCalciumSavings);
  const baseSavings = useRecoilValue(liqBaseSavings);
  const acidSavings = useRecoilValue(liqAcidSavings);

  const ixResinSavings = useRecoilValue(ionExchangeResinSavings);
  const ixChemSavings = useRecoilValue(ixChemicalSavings);
  const ixWasteWaterSavings = useRecoilValue(ionExchWasteWaterSavings);

  const waterSavings = useRecoilValue(ionExchWaterSavings);
  const sweetWaterSavings = useRecoilValue(ionExchSweetWaterSavings);

  const enzymeSavings = useRecoilValue(liqEnzymeSavings);

  const activeCurrency = useRecoilValue(currency);

  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">Savings Detail</Typography>

      <Box my={4} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h5"> Liquefaction savings</Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.label}>
              Liq. Calcium
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={calciumSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              Liq. Base
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={baseSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              Liq. Acid
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={acidSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              Enzymes
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={enzymeSavings} unit={`${activeCurrency} / day`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box pl={1}>
            <Typography variant="h5"> Ion exchange savings </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.label}>
              <Box pl={1}>IX Resins</Box>
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={ixResinSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              <Box pl={1}>IX Chemicals</Box>
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={ixChemSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              <Box pl={1}>IX Waste water</Box>
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={ixWasteWaterSavings} unit={`${activeCurrency} / day`} />
            </Grid>
            <Grid item xs={6} className={classes.label}>
              <Box pl={1}>IX Water</Box>
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={waterSavings} unit={`${activeCurrency} / day`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box height={28} />
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.label}>
              <Box pl={1}>IX Sweet water evaporation</Box>
            </Grid>
            <Grid item xs={6}>
              <ValueHighlight value={sweetWaterSavings} unit={`${activeCurrency} / day`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SavingsDetail;
