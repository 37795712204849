import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
} from '@material-ui/core';
import { Auth } from '@aws-amplify/auth';
import { DropDown, colors } from '@novozymes/components';
import { activeScenarioState } from 'state/atomState';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { getUserName } from 'utils/getUser';
import zIndexes from 'theme/zIndexes';
import { isActiveScenarioEdited } from 'state/getDerivedValues';
import navigateHome, { getHomeLink } from 'utils/navigateHome';

interface HeaderProps {
  isHomePage?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: ({ isHomePage }: HeaderProps): CreateCSSProperties => ({
    padding: theme.spacing(0, 3),
    backgroundColor: isHomePage ? colors.bg2 : theme.palette.secondary.main,
    color: isHomePage ? theme.palette.common.black : theme.palette.common.white,
  }),
  logoText: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 4),
    width: '300px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logo: {
    width: '90px',
  },
  logoSubtitle: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  headerTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    flex: 1,
  },
  userMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '16px',
    lineHeight: '24px',
    width: '300px',
  },
  userMenuIcon: {
    marginLeft: theme.spacing(1),
  },
  editText: {
    color: colors.white65,
  },
}));

const useMenuStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: zIndexes.medium,
  },
  menuList: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  menuListItem: {
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(1),
    minWidth: '200px',
    '&:hover': {
      backgroundColor: colors.bg3,
    },
  },
}));

type MenuItems = {
  id: string;
  label: string;
  onClick: () => void;
}[];

interface UserMenuProps {
  open: boolean;
  anchorRef: any;
  onClose: () => void;
  menuItems: MenuItems;
}

const UserMenu = (props: UserMenuProps): ReactElement => {
  const { open, anchorRef, onClose, menuItems } = props;
  const classes = useMenuStyles();

  return (
    <Popper
      open={open}
      className={classes.popper}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-end"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }): ReactElement => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList className={classes.menuList} id="menu-list-grow">
                {menuItems.map((menuItem) => (
                  <MenuItem id={menuItem.id} className={classes.menuListItem} onClick={menuItem.onClick}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const Header = (props: HeaderProps): ReactElement => {
  const classes = useStyles(props);

  const { isHomePage } = props;
  const activeScenario = useRecoilValue(activeScenarioState);
  const { title } = activeScenario;
  const isScenarioEdited = useRecoilValue(isActiveScenarioEdited);

  const [user, setUser] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const handleOnLogoClick = useCallback(() => {
    navigateHome();
  }, []);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    Auth.signOut();
  }, []);

  const handleQuitClick = useCallback(() => {
    navigateHome();
  }, []);

  const openLink = useCallback(
    (url?: string) => (): void => {
      if (url) {
        // eslint-disable-next-line no-undef
        window.location.assign(url);
      } else {
        // eslint-disable-next-line no-console
        console.log('No URL defined');
      }
    },
    []
  );

  useEffect(() => {
    getUserName().then((userName: any) => {
      setUser(userName);
    });
  }, []);

  const menuItems: MenuItems = [
    {
      id: 'menu-action-quit',
      label: 'Quit scenario',
      onClick: handleQuitClick,
    },
    {
      id: 'menu-action-terms',
      label: 'Terms & Condition',
      onClick: openLink(getHomeLink('terms')),
    },
    {
      id: 'menu-action-go-home',
      label: 'To MyNovozymes',
      onClick: openLink(process.env.REACT_APP_MY_NOVOZYMES_URL),
    },
    {
      id: 'menu-action-sign-out',
      label: 'Sign out',
      onClick: handleSignOut,
    },
  ];

  return (
    <>
      <Toolbar className={classes.headerContainer}>
        <Box id="home-logo" className={classes.logoText} onClick={handleOnLogoClick}>
          <img src={isHomePage ? '/nz_logo_black.svg' : '/nz_logo_white.svg'} alt="Novozymes logotype" className={classes.logo} />
          <Typography className={classes.logoSubtitle}>Refinery Optimiser</Typography>
        </Box>
        <Typography color="inherit" align="center" noWrap className={classes.headerTitle}>
          {!isHomePage && (
            <>
              {`Liquefaction: ${title}`}
              {isScenarioEdited && (
                <>
                  <Box component="span" className={classes.editText}>
                    {' - Edited'}
                  </Box>
                </>
              )}
            </>
          )}
        </Typography>
        <Box>
          <Box id="user-menu-open" className={classes.userMenu} onClick={handleMenuOpen}>
            <Typography ref={anchorRef}>{user}</Typography>
            <DropDown className={classes.userMenuIcon} />
          </Box>
          <UserMenu open={menuOpen} anchorRef={anchorRef} onClose={handleMenuClose} menuItems={menuItems} />
        </Box>
      </Toolbar>
    </>
  );
};

export default Header;
