import { EnzymeInput } from './EnzymeInput';
import isEnzymeInputValid from './EnzymeInputValidator';

const intercept = 1.8602799687;
const pH = 0.1467567524;
const dose = 0.0089477298;
const ca = 0.003855518;
const pHpH = -0.380942344;
const pHDose = -0.009775013;
const pHCa = -0.007425304;
const caCa = -0.000247402;
const time = -77.52180435;
const timeTime = 1435.7531557;
const timeTimeTime = -9908.652425;
const timePH = 14.81915543;
const timeTimePH = -145.2304183;
const timeDose = 0.2187742344;
const timeTimeDose = -2.15455469;

const pHGns = 4.99632;
const doseGns = 72.6038;
const caGns = 17.8058;

const calculation = (input: EnzymeInput, currentTime: number): number => {
  const cDosage = (input.dosage / 1000) * 1000 * 650;

  const result = Math.exp(
    intercept +
      pH * input.pH +
      dose * cDosage +
      ca * input.calcium +
      pHpH * (input.pH - pHGns) ** 2 +
      pHDose * (input.pH - pHGns) * (cDosage - doseGns) +
      pHCa * (input.pH - pHGns) * (input.calcium - caGns) +
      caCa * (input.calcium - caGns) ** 2 +
      time * (1 / currentTime) +
      timeTime * (1 / currentTime) ** 2 +
      timeTimeTime * (1 / currentTime) ** 3 +
      timePH * (1 / currentTime) * (input.pH - pHGns) +
      timeTimePH * (1 / currentTime) ** 2 * (input.pH - pHGns) +
      timeDose * (1 / currentTime) * (cDosage - doseGns) +
      timeTimeDose * (1 / currentTime) ** 2 * (cDosage - doseGns)
  );
  return result;
};

const simulation = (deNeo: number[]): number[] => {
  const res = [...deNeo];

  // Estimate DE at time 0
  res[0] = deNeo[1] - (deNeo[2] - deNeo[1]);

  return res;
};

const LPHeraNeo = (input: EnzymeInput): number[] => {
  if (!isEnzymeInputValid(input)) {
    return Array(9).fill(0);
  }

  const results: number[] = Array(9);
  const times: number[] = input.timeStep === 15 ? [0, 15, 30, 45, 60, 75, 90, 105, 120] : [30, 60, 90, 120, 150, 180, 210, 240];

  // eslint-disable-next-line no-plusplus
  for (let timeI = 0; timeI <= 8; timeI++) {
    results[timeI] = calculation(input, times[timeI]);
  }
  return simulation(results);
};

export default LPHeraNeo;
