import { AmplifyConfig, AmplifyFlowType, AmplifyResponseType } from '@novozymes-digital/auth';

export default {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: AmplifyFlowType.USER_PASSWORD_AUTH,
  oauth: {
    domain: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_DOMAIN,
    redirectSignOut: `${process.env.REACT_APP_DOMAIN}/logout`,
    responseType: AmplifyResponseType.CODE,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    identityProvider: process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER,
  },
} as AmplifyConfig;
