import React, { ReactElement } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import GridTable from 'components/GridTable';
import useInputWithState from 'state/useInputWithState';
import { Base, Acid } from 'calculation/types';
import { useRecoilValue } from 'recoil';
import { currency } from 'state/getDerivedValues';
import LinkToDocs from 'components/LinkToDocs';

const IonExchangeData = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const anion = {
    operation: useInputWithState<'Co-current' | 'Counter current'>({
      key: 'anOperation',
      type: 'Select',
      selectOptions: [
        {
          value: 'Co-current',
          label: 'Co-current',
        },
        {
          value: 'Counter current',
          label: 'Counter current',
        },
      ],
    }),
    resinCap: useInputWithState({ key: 'anResinCap', unit: 'eq / l resin' }),
    resinLife: useInputWithState({ key: 'anResinLife', unit: 'cycles' }),
    resinPrice: useInputWithState({ key: 'anResinPrice', unit: `${activeCurrency} / l resin` }),
    regChem: useInputWithState<Base>({
      key: 'anRegChem',
      type: 'Select',
      selectOptions: [
        {
          value: 'NaOH',
          label: 'NaOH',
        },
        {
          value: 'Na2CO3',
          label: 'Na2CO3',
        },
        {
          value: 'NH4OH',
          label: 'NH4OH',
        },
        {
          value: 'Ca(OH)2',
          label: 'Ca(OH)2',
        },
      ],
    }),
    chemConcentration: useInputWithState({ key: 'anChemConcentration', unit: '% (w/w)' }),
    chemPrice: useInputWithState({ key: 'anChemPrice', unit: `${activeCurrency} / kg` }),
    chemConsumption: useInputWithState({ key: 'anChemConsumption', unit: 'eq / l resin' }),
    water: useInputWithState({ key: 'anWater', unit: 'BV / reg.' }),
    SWDilution: useInputWithState({ key: 'anSWDilution', unit: 'BV / reg.' }),
    wasteWater: useInputWithState({ key: 'anWasteWater', unit: 'BV / reg.' }),
    crossRegen: useInputWithState({ key: 'anCrossRegen', unit: 'cycles' }),
  };
  const cation = {
    operation: useInputWithState<'Co-current' | 'Counter current'>({
      key: 'catOperation',
      type: 'Select',
      selectOptions: [
        {
          value: 'Co-current',
          label: 'Co-current',
        },
        {
          value: 'Counter current',
          label: 'Counter current',
        },
      ],
    }),
    resinCap: useInputWithState({ key: 'catResinCap', unit: 'eq / l resin' }),
    resinLife: useInputWithState({ key: 'catResinLife', unit: 'cycles' }),
    resinPrice: useInputWithState({ key: 'catResinPrice', unit: `${activeCurrency} / l resin` }),
    regChem: useInputWithState<Acid>({
      key: 'catRegChem',
      type: 'Select',
      selectOptions: [
        {
          value: 'HCl',
          label: 'HCl',
        },
        {
          value: 'H2SO4',
          label: 'H2SO4',
        },
      ],
    }),
    chemConcentration: useInputWithState({ key: 'catChemConcentration', unit: '% (w/w)' }),
    chemPrice: useInputWithState({ key: 'catChemPrice', unit: `${activeCurrency} / kg` }),
    chemConsumption: useInputWithState({ key: 'catChemConsumption', unit: 'eq / l resin' }),
    water: useInputWithState({ key: 'catWater', unit: 'BV / reg.' }),
    SWDilution: useInputWithState({ key: 'catSWDilution', unit: 'BV / reg.' }),
    wasteWater: useInputWithState({ key: 'catWasteWater', unit: 'BV / reg.' }),
    crossRegen: useInputWithState({ key: 'catCrossRegen', unit: 'cycles' }),
  };

  const [, waterPriceInput] = useInputWithState({
    key: 'waterPrice',
    unit: `${activeCurrency} / m³`,
  });
  const [, wasteWaterCostInput] = useInputWithState({
    key: 'wasteWaterCost',
    unit: `${activeCurrency} / m³`,
  });

  const columns = [
    {
      heading: 'IX Input data',
      data: [
        'Operation',
        'Resin capacity',
        'Resin lifetime',
        'Resin price',
        'Regeneration chemical',
        'Chemical concentration',
        'Chemical price',
        'Chemical consumption',
      ],
    },
    {
      heading: 'Cation',
      data: Object.values(cation)
        .slice(0, 8)
        .map((val) => <Grid item>{val[1]}</Grid>),
    },
    {
      heading: 'Anion',
      data: Object.values(anion)
        .slice(0, 8)
        .map((val) => <Grid item>{val[1]}</Grid>),
    },
    {
      data: [
        'Water',
        'Sweet water dilution',
        'Waste water',
        'Cross regeneration',
        <Box mt={2} />,
        'Water price',
        'Waste water cost',
      ],
    },
    {
      heading: 'Cation',
      data: Object.values(cation)
        .slice(8, 12)
        .map((val) => <Grid item>{val[1]}</Grid>)
        .concat([<Box mt={3} />, <Grid item> {waterPriceInput} </Grid>, <Grid item> {wasteWaterCostInput} </Grid>]),
    },
    {
      heading: 'Anion',
      data: Object.values(anion)
        .slice(8, 12)
        .map((val) => <Grid item>{val[1]}</Grid>),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Ion exchange data</Typography>
        <LinkToDocs fileName="ion_exchange" text="Understanding ion exchange" />
      </Box>
      <GridTable columns={columns} />
    </>
  );
};

export default IonExchangeData;
