import React, { ReactElement } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { reductionOfCalcium, reductionOfChemicals, totalIonExchangeReduction } from 'state/getDerivedValues';
import ValueHighlight from 'components/ValueHighlight';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const ReductionInLoadFromLiq = (): ReactElement => {
  const calciumReduction = useRecoilValue(reductionOfCalcium);
  const chemicalReduction = useRecoilValue(reductionOfChemicals);
  const totalReduction = useRecoilValue(totalIonExchangeReduction);

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} />
      <Grid item xs={4} className={classes.heading}>
        Cation
      </Grid>
      <Grid item xs={4} className={classes.heading}>
        Anion
      </Grid>
      <Grid item xs={4} className={classes.label}>
        <Box pl={1}>Calcium</Box>
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={calciumReduction} unit="eq / day" />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={calciumReduction} unit="eq / day" />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        <Box pl={1}>Base</Box>
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={chemicalReduction} unit="eq / day" />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={0} unit="eq / day" />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        <Box pl={1}>Acid</Box>
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={0} unit="eq / day" />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={chemicalReduction} unit="eq / day" />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        <Box pl={1}>Total</Box>
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={totalReduction} unit="eq / day" highlight />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={totalReduction} unit="eq / day" highlight />
      </Grid>
    </Grid>
  );
};

export default ReductionInLoadFromLiq;
