/* eslint-disable class-methods-use-this */
import { EnzymeModel } from './EnzymeModel';
import { EnzymeInput } from './EnzymeInput';
import LiquozymeSupra from './LiquozymeSupra';

const LiquozymeSupra28X: EnzymeModel = (input: EnzymeInput): number[] => {
  const input22x: EnzymeInput = { ...input, dosage: input.dosage * 2.8 };

  return LiquozymeSupra(input22x);
};

export default LiquozymeSupra28X;
