import roundNumber from 'utils/roundNumber';
import logger from 'utils/logger';
import sweetWaterEvaporation, {
  ThermalVaporRecompressionInput,
  MechanicalVaporRecompressionInput,
} from './sweetWaterEvaporation';

const { thermalVaporRecompression, mechanicalVaporRecompression } = sweetWaterEvaporation;

const genericWaterCalc = ({
  resourcePrice,
  resourceUsage,
  resinCapacity,
}: {
  resourcePrice: number;
  resourceUsage: number;
  resinCapacity: number;
}): number => {
  const nonRoundedValue = (resourcePrice / 1000) * (resourceUsage / resinCapacity);
  return roundNumber(nonRoundedValue, 4);
};

export type CalcWasteWaterInput = {
  resinCapacity: number;
  wasteWaterUsage: number;
  costWasteWater: number;
};
const calcWasteWater = ({ costWasteWater, wasteWaterUsage, resinCapacity }: CalcWasteWaterInput): number =>
  genericWaterCalc({
    resourcePrice: costWasteWater,
    resourceUsage: wasteWaterUsage,
    resinCapacity,
  });

export type CalcWaterInput = {
  priceWater: number;
  waterUsage: number;
  resinCapacity: number;
};
const calcWater = ({ priceWater, waterUsage, resinCapacity }: CalcWaterInput): number =>
  genericWaterCalc({
    resourcePrice: priceWater,
    resourceUsage: waterUsage,
    resinCapacity,
  });

export type EvaporatorType = 'MVR' | 'TVR';

export type CalcSweetWaterEvaporationInput = {
  evaporatorType: EvaporatorType;
  evaporatorInput: MechanicalVaporRecompressionInput | ThermalVaporRecompressionInput | undefined;
  sweetWaterUsage: number;
  resinCapacity: number;
};

const getSweetWaterEvapCost = (
  evaporatorType: EvaporatorType,
  evaporatorInput: MechanicalVaporRecompressionInput | ThermalVaporRecompressionInput | undefined
): number => {
  if (evaporatorType === 'TVR' && evaporatorInput) {
    return thermalVaporRecompression(evaporatorInput as ThermalVaporRecompressionInput);
  }
  if (evaporatorType === 'MVR' && evaporatorInput) {
    return mechanicalVaporRecompression(evaporatorInput as MechanicalVaporRecompressionInput);
  }
  logger.error(`Evaporator type ${evaporatorType} is unknown or evaporatorInput is undefined`);

  return 0;
};

const calcSweetWaterEvaporation = ({
  evaporatorType,
  evaporatorInput,
  sweetWaterUsage,
  resinCapacity,
}: CalcSweetWaterEvaporationInput): number => {
  if (!evaporatorInput) {
    return 0;
  }
  const costEvapSweetWater = getSweetWaterEvapCost(evaporatorType, evaporatorInput);

  return genericWaterCalc({
    resourcePrice: costEvapSweetWater,
    resourceUsage: sweetWaterUsage,
    resinCapacity,
  });
};

export default {
  calcWasteWater,
  calcWater,
  calcSweetWaterEvaporation,
};
