import React, { ReactElement } from 'react';
import { Typography, Grid, makeStyles, Box } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import useInputWithState from 'state/useInputWithState';
import DEChartsWrapper from 'components/DEChartsWrapper';
import { newEnzymeModelOutput, refEnzymeModelOutput, availableEnyzmes, enzymeRanges } from 'state/getDerivedValues';
import { DEMethodType } from 'calculation/enzymeModels/EnzymeInput';
import getAtomState from 'state/atomState';
import { TimeStep } from 'scenarios/ScenarioType';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

export default (): ReactElement => {
  const models = useRecoilValue(availableEnyzmes);

  const timeStep = useRecoilValue(getAtomState<TimeStep>('timeStep'));
  const ENZYME_RANGES = useRecoilValue(enzymeRanges);

  const [newEnzymeName, newEnzymeNameInput] = useInputWithState<string>({
    key: 'newEnzymeName',
    type: 'Select',
    helperText: 'Select new enzyme',
    selectOptions: Object.keys(models)
      .filter((modelName) => modelName !== 'Competitor')
      .map((modelName) => ({ value: modelName, label: modelName })),
  });
  const newEnzData = newEnzymeName ? ENZYME_RANGES[newEnzymeName] : undefined;
  const [, newEnzymeDosageInput] = useInputWithState({
    key: 'newEnzymeDosage',
    helperText: newEnzData && `${newEnzData.dosage.min} - ${newEnzData.dosage.max}`,
    unit: 'kg / tDS',
  });
  const [, newEnzymepHInput] = useInputWithState<number>({
    key: 'newEnzymepH',
    helperText: newEnzData && `${newEnzData.ph.min} - ${newEnzData.ph.max}`,
  });
  const [, newEnzymeCalciumInput] = useInputWithState<number>({
    key: 'newEnzymeCalcium',
    helperText: newEnzData && `${newEnzData.calcium.min} - ${newEnzData.calcium.max}`,
  });

  const [refEnzymeName, refEnzymeNameInput] = useInputWithState<string>({
    key: 'refEnzymeName',
    type: 'Select',
    helperText: 'Select reference enzyme',
    selectOptions: Object.keys(models).map((modelName) => ({ value: modelName, label: modelName })),
  });
  const refEnzData = refEnzymeName ? ENZYME_RANGES[refEnzymeName] : undefined;

  const [, refEnzymeDosageInput] = useInputWithState({
    key: 'refEnzymeDosage',
    helperText: refEnzData && `${refEnzData.dosage.min} - ${refEnzData.dosage.max}`,
    unit: 'kg / tDS',
  });
  const [, refEnzymepHInput] = useInputWithState({
    key: 'refEnzymepH',
    helperText: refEnzData && `${refEnzData.ph.min} - ${refEnzData.ph.max}`,
  });
  const [, refEnzymeCalciumInput] = useInputWithState({
    key: 'refEnzymeCalcium',
    helperText: refEnzData && `${refEnzData.calcium.min} - ${refEnzData.calcium.max}`,
  });

  const [, competitorDEMethodInput] = useInputWithState<DEMethodType>({
    key: 'competitorDEMethod',
    type: 'Select',
    selectOptions: [
      { label: 'Fehling', value: 'Fehling' },
      { label: 'Neocuproin', value: 'Neocuproin' },
    ],
  });
  const [, liquefactionDEInput] = useInputWithState({
    key: 'liquefactionDE',
  });
  const [, liquefactionTimeInput] = useInputWithState({
    key: 'liquefactionTime',
  });

  const refModelPlotData = useRecoilValue(refEnzymeModelOutput);
  const newModelPlotData = useRecoilValue(newEnzymeModelOutput);

  const graphData =
    refModelPlotData && newModelPlotData && timeStep
      ? newModelPlotData.map((modelADataPoint, i) => ({
          time: i * timeStep,
          modelA: modelADataPoint,
          modelB: refModelPlotData[i],
        }))
      : [];
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <Box pr={4}>
          <Grid container spacing={2}>
            <Grid item xs={4} />

            <Grid item xs={4} className={classes.heading}>
              Reference
            </Grid>
            <Grid item xs={4} className={classes.heading}>
              New solution
            </Grid>
            <Grid item xs={4} className={classes.label}>
              Enzyme type
            </Grid>
            <Grid item xs={4}>
              {refEnzymeNameInput}
            </Grid>
            <Grid item xs={4}>
              {newEnzymeNameInput}
            </Grid>

            <Grid item xs={4} className={classes.label}>
              Dosage
            </Grid>
            <Grid item xs={4}>
              {refEnzymeDosageInput}
            </Grid>
            <Grid item xs={4}>
              {newEnzymeDosageInput}
            </Grid>

            <Grid item xs={4} className={classes.label}>
              pH
            </Grid>
            <Grid item xs={4}>
              {refEnzymepHInput}
            </Grid>
            <Grid item xs={4}>
              {newEnzymepHInput}
            </Grid>

            <Grid item xs={4} className={classes.label}>
              Calcium ppm added
            </Grid>
            <Grid item xs={4}>
              {refEnzymeCalciumInput}
            </Grid>
            <Grid item xs={4}>
              {newEnzymeCalciumInput}
            </Grid>
          </Grid>
        </Box>
        <Box my={4} />
        {refEnzymeName === 'Competitor' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.heading}>
                Competitor DE Data
              </Typography>
              <Typography variant="body1">
                Enter the secondary liquefaction time and the final DE % for the competitor enzyme. Select the DE measurement
                method.
              </Typography>
            </Grid>

            <Grid item xs={4} className={classes.heading}>
              DE method
            </Grid>
            <Grid item xs={4} className={classes.heading}>
              DE
            </Grid>
            <Grid item xs={4} className={classes.heading}>
              Time
            </Grid>

            <Grid item xs={4}>
              {competitorDEMethodInput}
            </Grid>
            <Grid item xs={4}>
              {liquefactionDEInput}
            </Grid>
            <Grid item xs={4}>
              {liquefactionTimeInput}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item sm={12} md={6}>
        <Box pl={4}>
          <DEChartsWrapper data={graphData} modelAName={newEnzymeName} modelBName={refEnzymeName} height={400} />
        </Box>
      </Grid>
    </Grid>
  );
};
