import React, { ReactElement } from 'react';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import useInputWithState from 'state/useInputWithState';
import ValueHighlight from 'components/ValueHighlight';
import calcSlurryDensity from 'calculation/utils/calcSlurryDensity';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const Process = (): ReactElement => {
  const [, plantCapacityInput] = useInputWithState<number>({
    key: 'plantCapacity',
    unit: 'tDS starch / day',
  });
  const [DSSlurryLiq, DSSlurryLiqInput] = useInputWithState<number>({
    key: 'DSSlurryLiq',
    unit: '%',
  });
  const [, slurryLiquidpHInput] = useInputWithState<number>({
    key: 'slurryLiquidpH',
  });

  const [, saccharificationpHInput] = useInputWithState<number>({
    key: 'saccharificationpH',
  });

  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">Process</Typography>
      <Grid container spacing={2}>
        <Grid item xs={2} className={classes.label}>
          Plant capacity
        </Grid>
        <Grid item xs={2}>
          {plantCapacityInput}
        </Grid>
      </Grid>
      <Box my={2} />
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={2} className={classes.heading}>
          pH
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          DS
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          Density
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={2} className={classes.label}>
          Starch slurry
        </Grid>
        <Grid item xs={2}>
          {slurryLiquidpHInput}
        </Grid>
        <Grid item xs={2}>
          {DSSlurryLiqInput}
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={calcSlurryDensity(DSSlurryLiq)} unit="kg/l" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2} className={classes.label}>
          Saccharification
        </Grid>
        <Grid item xs={2}>
          {saccharificationpHInput}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2} />
      </Grid>
    </>
  );
};

export default Process;
