import React, { ReactElement } from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import useInputWithState from 'state/useInputWithState';
import { Effects } from 'calculation/ionExchange/sweetWaterEvaporation';
import { EvaporatorType } from 'calculation/ionExchange/calcWaterCost';
import ValueHighlight from 'components/ValueHighlight';
import calcSteamConsumption from 'calculation/utils/calcSteamConsumption';
import { useRecoilValue } from 'recoil';
import { currency } from 'state/getDerivedValues';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const IonExchangeWaterInput = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const [evaporatorType, evaporatorTypeInput] = useInputWithState<EvaporatorType>({
    key: 'evaporatorType',
    label: 'Evaporator type',
    type: 'Radio',
    selectOptions: [
      {
        value: 'MVR',
        label: 'MVR',
      },
      {
        value: 'TVR',
        label: 'TVR',
      },
    ],
  });

  const [, electricityPriceInput] = useInputWithState({
    key: 'electricityPrice',
    unit: `${activeCurrency} / kWh`,
  });
  const [, electricityConsumptionInput] = useInputWithState({
    key: 'electricityConsumption',
    unit: 'kWh / t evap',
  });

  const [effects, effectsInput] = useInputWithState<Effects>({
    key: 'effects',
    type: 'Select',
    selectOptions: [
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4+',
        label: '4+',
      },
    ],
  });
  const [, steamPriceInput] = useInputWithState({
    key: 'steamPrice',
    unit: `${activeCurrency} / t steam`,
  });

  const classes = useStyles();

  return (
    <Box my={2}>
      <Typography variant="h2">Evaporation</Typography>
      <Grid container>
        <Grid item>{evaporatorTypeInput}</Grid>
      </Grid>

      {evaporatorType === 'TVR' ? (
        <Grid container spacing={2}>
          <Grid item xs={2} />
          <Grid item xs={2} className={classes.heading}>
            Effects
          </Grid>
          <Grid item xs={2} className={classes.heading}>
            T steam / t evap
          </Grid>
          <Grid item xs={2} className={classes.heading}>
            Steam price
          </Grid>

          <Grid item xs={4} />

          <Grid item xs={2} className={classes.label}>
            {evaporatorType}
          </Grid>
          <Grid item xs={2}>
            {effectsInput}
          </Grid>
          <Grid item xs={2}>
            <ValueHighlight value={calcSteamConsumption(effects)} unit="t steam / t evap" />
          </Grid>
          <Grid item xs={2}>
            {steamPriceInput}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={2} />
          <Grid item xs={2} className={classes.heading}>
            Effects
          </Grid>
          <Grid item xs={2} className={classes.heading}>
            Electricity consumption
          </Grid>
          <Grid item xs={2} className={classes.heading}>
            Electricity price
          </Grid>

          <Grid item xs={4} />

          <Grid item xs={2} className={classes.label}>
            {evaporatorType}
          </Grid>
          <Grid item xs={2}>
            <ValueHighlight value={1} />
          </Grid>
          <Grid item xs={2}>
            {electricityConsumptionInput}
          </Grid>
          <Grid item xs={2}>
            {electricityPriceInput}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}
    </Box>
  );
};

export default IonExchangeWaterInput;
