import React, { ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';
import { useTheme } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { currency } from 'state/getDerivedValues';
import { colors } from '@novozymes/components';
import SavingsChart, { SavingsChartEntry } from './SavingsChart';

export interface SavingsChartWrapperProps {
  data: SavingsChartEntry[];
}

function SavingsChartWrapper({ data }: SavingsChartWrapperProps): ReactElement {
  const theme = useTheme();
  const activeCurrency = useRecoilValue(currency);
  return (
    <ResponsiveContainer height={500}>
      <SavingsChart
        data={data}
        toolTipColor={theme.palette.primary.dark}
        barColor={colors.softGreen}
        barHighlightColor={colors.primaryGreen}
        showToolTip
        currency={activeCurrency}
      />
    </ResponsiveContainer>
  );
}

export default SavingsChartWrapper;
