import DEMethods from './DEMethods';
import { EnzymeModel } from './EnzymeModel';
import { EnzymeInput } from './EnzymeInput';
import isEnzymeInputValid from './EnzymeInputValidator';

const Termamyl2X2H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  const F35 = Math.exp(-0.924 + 0.023 * input.calcium);
  const F32 = Math.exp(-0.0521 * input.pH ** 3 + 0.11 * input.pH ** 2 + 5.93 * input.pH - 28.885);
  const H26 = 500 - (196 + 3.6 * input.calcium);
  const H27 = Math.exp(0.002058 * H26);
  const G29 = 52.1907 * H27 * F35 * 0.9996 * F32;
  const F22 = (Math.log(2) * 5) / G29;
  const F28 = (input.dosage / 10) * Math.exp(-F22);
  const F29 = (6.2348 * 2 * (input.dosage / 10) * G29 * (1 - Math.exp(-F22))) / (6 * Math.log(2));
  const F37 = 730.736 * H27 * F35 * 0.9996 * F32;
  const F38 = (Math.log(2) * time) / F37;
  const F31 = (5.49525 * 2 * F28 * F37 * (1 - Math.exp(-F38))) / (6 * Math.log(2));
  return fehlingsB + fehlingsA * ((F31 + F29 - 0.355) / 0.8085);
};

const Termamyl2X4H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (76.221 +
        -216.52 * input.dosage +
        -12.252 * input.pH +
        0.019981 * time +
        -0.0075063 * input.calcium +
        -0.000056325 * time ** 2 +
        25.49 * input.dosage ** 2 +
        34.037 * input.dosage * input.pH +
        0.12188 * time * input.dosage +
        0.00011261 * input.calcium * time)
  );
};

const Termamyl2X: EnzymeModel = (input) => {
  const simulation: number[] = Array(9).fill(0);

  if (!isEnzymeInputValid(input)) {
    return simulation;
  }

  // This is for Teramyl 2X which seems to be the only one used
  const input2x = { ...input, dosage: input.dosage * 2 };

  const model = input.timeStep === 15 ? Termamyl2X2H : Termamyl2X4H;

  for (let timeI = 0; timeI < simulation.length; timeI += 1) {
    const time = timeI * input.timeStep;

    simulation[timeI] = model(input2x, time);
  }

  return simulation;
};

export default Termamyl2X;
