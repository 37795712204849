import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  makeStyles,
  IconButton,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect, ReactElement, ChangeEvent } from 'react';
import { Scenario } from 'scenarios/ScenarioType';
import CloseIcon from '@material-ui/icons/Close';
import { colors, Button, OutlinedInputWithLabel } from '@novozymes/components';
import API from 'API';
import * as EmailValidator from 'email-validator';
import logger from 'utils/logger';
import { getUserEmail } from 'utils/getUser';

export interface ShareScenarioDialogProps {
  show: boolean;
  scenario?: Scenario;
  onClose(): void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: colors.black80,
  },
}));

const ShareScenarioDialog = ({ show, scenario, onClose }: ShareScenarioDialogProps): ReactElement => {
  const [open, setOpen] = useState(show);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClose = useCallback(() => {
    setOpen(false);
    setEmail('');
    setError('');
    setSuccess(false);
    onClose();
  }, []);

  const handleShare = useCallback(async () => {
    setError('');
    if (!EmailValidator.validate(email)) {
      setError('Please enter a valid email');
      return;
    }

    const userEmail = await getUserEmail();

    if (userEmail === email) {
      setError('You cannot share the scenario with yourself. Please enter a different email.');
      return;
    }

    if (!scenario?.id) {
      setError('Please save the scenario before sharing the scenario');
      return;
    }

    setLoading(true);

    const body = {
      shareWithEmail: email,
    };

    try {
      const response = await API.Scenarios.share({ id: scenario?.id, body });
      const responseData = response.data<{ statusCode: string; body: any; headers: any }>();

      if (responseData.statusCode === '201') {
        setError('');
        setSuccess(true);
      } else {
        setError('Please make sure the shared user has a Salesforce account');
      }
    } catch (e) {
      logger.error('Failed to share scenario' + e);
      setError('Something went wrong please try again');
    } finally {
      setLoading(false);
    }
  }, [email, scenario]);

  useEffect(() => {
    if (scenario !== undefined) setOpen(show);
  }, [show, scenario]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle disableTypography={false}>
        <Box mt={2} mx={3}>
          <Typography variant="body1">Share a copy of scenario</Typography>
        </Box>

        <IconButton
          id="btn-share-dialog-close"
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mx={2}>
          {success ? (
            <>
              <Typography id="successful-share-info" variant="body1">{`Scenario successfully shared with ${email}`}</Typography>
            </>
          ) : (
            <>
              <Box mb={2}>
                <Typography variant="body1">Invite another user to view and edit a copy of the scenario</Typography>
              </Box>
              <OutlinedInputWithLabel
                label="Share calculation with"
                value={email}
                placeholder="email"
                handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  const { value: newValue } = event.target;
                  setEmail(newValue);
                }}
                error={!!error}
                errorMessage={error}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={2} mt={4} mr={2}>
          {success ? (
            <>
              <Button id="btn-share-dialog-success-close" type="primary" onClick={handleClose}>
                Close
              </Button>
            </>
          ) : (
            <>
              <Button id="btn-share-dialog-cancel" type="secondary" onClick={handleClose}>
                Cancel
              </Button>
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <Button id="btn-share-dialog-share" type="primary" onClick={handleShare}>
                  Share
                </Button>
              )}
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ShareScenarioDialog;
