import React, { ReactElement } from 'react';
import { Typography, Grid, makeStyles, Box } from '@material-ui/core';
import useInputWithState from 'state/useInputWithState';
import LinkToDocs from 'components/LinkToDocs';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const SlurryTitration = (): ReactElement => {
  const [, titrationCoefficientAInput] = useInputWithState({
    key: 'titrationCoefficientA',
  });
  const [, titrationCoefficientBInput] = useInputWithState({
    key: 'titrationCoefficientB',
  });
  const [, titrationCoefficientCInput] = useInputWithState({
    key: 'titrationCoefficientC',
  });
  const [, titrationCoefficientDInput] = useInputWithState({
    key: 'titrationCoefficientD',
  });
  const [, titrationCoefficientEInput] = useInputWithState({
    key: 'titrationCoefficientE',
  });
  const [, DSSlurrySampleInput] = useInputWithState({
    key: 'DSSlurrySample',
    unit: '%',
  });

  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2">Slurry Titration</Typography>
          <Box maxWidth={440} mb={4}>
            <Typography variant="body1">
              Enter the regression coefficients from the slurry titration graph and the DS % of the titrated slurry.
            </Typography>
          </Box>
        </Box>
        <LinkToDocs fileName="slurry_titration" text="How to measure slurry titration" />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={2} />

        <Grid item xs={2} className={classes.heading}>
          A
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          B
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          C
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          D
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          E
        </Grid>

        <Grid item xs={2} className={classes.label}>
          Coefficients
        </Grid>
        <Grid item xs={2}>
          {titrationCoefficientAInput}
        </Grid>
        <Grid item xs={2}>
          {titrationCoefficientBInput}
        </Grid>
        <Grid item xs={2}>
          {titrationCoefficientCInput}
        </Grid>
        <Grid item xs={2}>
          {titrationCoefficientDInput}
        </Grid>
        <Grid item xs={2}>
          {titrationCoefficientEInput}
        </Grid>
        <Grid item xs={2} className={classes.label}>
          DS slurry sample
        </Grid>
        <Grid item xs={2}>
          {DSSlurrySampleInput}
        </Grid>
      </Grid>
    </>
  );
};

export default SlurryTitration;
