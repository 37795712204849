/* eslint-disable class-methods-use-this */

import { EnzymeModel } from './EnzymeModel';
import { EnzymeInput } from './EnzymeInput';
import SuhongAAPlus from './SuhongAAPlus';

const SuhongAAPlus2X: EnzymeModel = (input: EnzymeInput): number[] => {
  const input2x: EnzymeInput = { ...input, dosage: input.dosage * 2 };

  return SuhongAAPlus(input2x);
};

export default SuhongAAPlus2X;
