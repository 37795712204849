import { ScenarioDataKey, ScenarioData } from './ScenarioType';
import getEnzymeRange from './enzymeData';

type EvaluationFunction = (input: any, scenarioData?: ScenarioData) => string | void;

const isEmpty = (input: any): boolean => input === undefined || input === '' || input === ' ';
const getEmptyMessage = (nameOfField?: string): string =>
  `Please enter a value ${!isEmpty(nameOfField) ? `for ${nameOfField}` : ''}`;

const getEmptyWithRangeMessage = (range: { max: number; min: number }): string =>
  `Please enter a value between ${range.min}-${range.max}`;
const getOutOfRangeMessage = (range: { max: number; min: number }): string => `Must be between ${range.min} and ${range.max}`;

const checkIfEmpty: (nameOfField?: string) => EvaluationFunction =
  (nameOfField) =>
  (input): string | void => {
    if (isEmpty(input)) {
      return getEmptyMessage(nameOfField);
    }
  };

const isNumber = (value: any): boolean => {
  const parsedValue = parseFloat(value);
  return typeof parsedValue === 'number' && !Number.isNaN(parsedValue);
};

const checkIfInRange =
  (min: number, max: number) =>
  (value: number): string | undefined => {
    if (isEmpty(value) || !isNumber(value)) {
      return getEmptyWithRangeMessage({ min, max });
    }

    if (max < value || min > value) {
      return getOutOfRangeMessage({ min, max });
    }
  };

const checkEnzRange: (nameOfField: string, enzNameKey: ScenarioDataKey, dataKey: ScenarioDataKey) => EvaluationFunction =
  (nameOfField, enzNameKey, dataKey) =>
  (input, scenarioData): string | void => {
    if (!scenarioData) {
      return;
    }

    const enzName = scenarioData[enzNameKey] as string | undefined;
    if (!enzName) {
      return;
    }
    const { timeStep } = scenarioData;
    const range = getEnzymeRange(enzName, dataKey, timeStep);

    if (!range) {
      if (isEmpty(input)) {
        return getEmptyMessage(nameOfField);
      }
      return;
    }

    if (isEmpty(input)) {
      return getEmptyWithRangeMessage(range);
    }

    if (input > range.max || input < range.min) {
      return getOutOfRangeMessage(range);
    }
  };

const checkIfFieldIsValue =
  (key: ScenarioDataKey, equalTo: any, evalFunc: EvaluationFunction) =>
  (input: any, scenarioData?: ScenarioData): string | void => {
    if (!scenarioData) {
      return;
    }

    const value = scenarioData[key];

    if (value === equalTo) {
      return evalFunc(input, scenarioData);
    }
  };

type SectionValidators = Partial<Record<ScenarioDataKey, EvaluationFunction>>;

const enzymesSection: SectionValidators = {
  newEnzymeName: checkIfEmpty('New enzyme'),
  newEnzymeDosage: checkEnzRange('New enzyme dosage', 'newEnzymeName', 'newEnzymeDosage'),
  newEnzymepH: checkEnzRange('New enzyme pH', 'newEnzymeName', 'newEnzymepH'),
  newEnzymeCalcium: checkEnzRange('New enzyme calcium', 'newEnzymeName', 'newEnzymeCalcium'),
  refEnzymeName: checkIfEmpty('Reference enzyme'),
  refEnzymeDosage: checkEnzRange('Reference enzyme dosage', 'refEnzymeName', 'refEnzymeDosage'),
  refEnzymepH: checkEnzRange('Reference enzyme pH', 'refEnzymeName', 'refEnzymepH'),
  refEnzymeCalcium: checkEnzRange('Reference enzyme calcium', 'refEnzymeName', 'refEnzymeCalcium'),
  competitorDEMethod: checkIfEmpty('Competitor DE Method'),
  liquefactionDE: checkIfFieldIsValue('refEnzymeName', 'Competitor', checkIfEmpty('Liquefaction DE')),
  liquefactionTime: checkIfFieldIsValue('refEnzymeName', 'Competitor', checkIfEmpty('Liquefaction time')),
  DEMethod: checkIfEmpty('DE method'),
  timeStep: checkIfEmpty('DE model'),
};

const processSection: SectionValidators = {
  plantCapacity: checkIfEmpty(),
  DSSlurryLiq: checkIfInRange(0, 100),
  slurryLiquidpH: checkIfInRange(0, 14),
  saccharificationpH: checkIfInRange(0, 14),
};

const chemicalsSection: SectionValidators = {
  baseChemicalName: checkIfEmpty(),
  baseChemicalPrice: checkIfEmpty(),
  baseChemicalConcentration: checkIfInRange(0, 100),
  acidChemicalName: checkIfEmpty(),
  acidChemicalPrice: checkIfEmpty(),
  acidChemicalConcentration: checkIfInRange(0, 100),
  calciumSourceName: checkIfEmpty(),
  calciumPrice: checkIfEmpty(),
  calciumConcentration: checkIfInRange(0, 100),
};

const slurryTitrationSection: SectionValidators = {
  titrationCoefficientA: checkIfEmpty(),
  titrationCoefficientB: checkIfEmpty(),
  titrationCoefficientC: checkIfEmpty(),
  titrationCoefficientD: checkIfEmpty(),
  titrationCoefficientE: checkIfEmpty(),
  DSSlurrySample: checkIfInRange(0, 100),
};

const evaporationSection: SectionValidators = {
  evaporatorType: checkIfEmpty(),
  electricityPrice: checkIfFieldIsValue('evaporatorType', 'MVR', checkIfEmpty()),
  electricityConsumption: checkIfFieldIsValue('evaporatorType', 'MVR', checkIfEmpty()),
  effects: checkIfFieldIsValue('evaporatorType', 'TVR', checkIfEmpty()),
  steamPrice: checkIfFieldIsValue('evaporatorType', 'TVR', checkIfEmpty()),
};

const ionExchangeSection: SectionValidators = {
  anOperation: checkIfEmpty(),
  anResinCap: checkIfEmpty(),
  anResinLife: checkIfEmpty(),
  anResinPrice: checkIfEmpty(),
  anRegChem: checkIfEmpty(),
  anChemConcentration: checkIfInRange(0, 100),
  anChemPrice: checkIfEmpty(),
  anChemConsumption: checkIfEmpty(),
  anWater: checkIfEmpty(),
  anSWDilution: checkIfEmpty(),
  anWasteWater: checkIfEmpty(),
  anCrossRegen: checkIfEmpty(),
  catOperation: checkIfEmpty(),
  catResinCap: checkIfEmpty(),
  catResinLife: checkIfEmpty(),
  catResinPrice: checkIfEmpty(),
  catChemConcentration: checkIfInRange(0, 100),
  catChemPrice: checkIfEmpty(),
  catChemConsumption: checkIfEmpty(),
  catWater: checkIfEmpty(),
  catSWDilution: checkIfEmpty(),
  catWasteWater: checkIfEmpty(),
  catCrossRegen: checkIfEmpty(),
  waterPrice: checkIfEmpty(),
  wasteWaterCost: checkIfEmpty(),
};

const validators: SectionValidators[] = [
  { ...enzymesSection },
  {
    ...processSection,
    ...chemicalsSection,
    ...slurryTitrationSection,
    ...evaporationSection,
    ...ionExchangeSection,
  },
];

type InputErrors = Partial<Record<ScenarioDataKey, string>>;

const getScenarioValidationErrors =
  (routeIndex: 0 | 1) =>
  (scenarioData: ScenarioData): InputErrors => {
    const validatorsForPage = validators[routeIndex];

    const errorMessages: InputErrors = (Object.keys(scenarioData) as ScenarioDataKey[]).reduce((acc, scenarioKey) => {
      const validator = validatorsForPage[scenarioKey];
      if (validator) {
        const error = validator(scenarioData[scenarioKey], scenarioData);
        if (error) {
          return { ...acc, [scenarioKey]: error };
        }
      }

      return acc;
    }, {} as any);
    return errorMessages;
  };

export default getScenarioValidationErrors;
