/* eslint-disable no-mixed-operators */
import roundNumber from 'utils/roundNumber';
import { Acid, Base } from 'calculation/types';

export type CalcChemicalInput = {
  acidName: Acid;
  baseName: Base;
  crossRegeneration: number;
  acidChemicalConcentration: number;
  resinCapacity: number;
  acidChemicalPrice: number;
  acidChemicalConsumption: number;
  baseChemicalConsumption: number;
  baseChemicalPrice: number;
  baseChemicalConcentration: number;
};

// eq / kg 100 % acid source,
const EQ_ACID = {
  HCl: 27.4,
  H2SO4: 20.4,
};

//  eq / kg 100 % base source
const EQ_BASE = {
  NaOH: 25,
  Na2CO3: 18.9,
  NH4OH: 28.57,
  'Ca(OH)2': 27.0,
};

const calcCationChemical = ({
  acidName,
  baseName,
  crossRegeneration,
  acidChemicalConsumption,
  resinCapacity,
  acidChemicalPrice,
  acidChemicalConcentration,
  baseChemicalPrice,
  baseChemicalConcentration,
}: CalcChemicalInput): number => {
  const CrossRegFactor = crossRegeneration === 0 ? 0 : 1 / Math.abs(crossRegeneration);

  const nonRoundedValue =
    (1 + CrossRegFactor) *
      ((acidChemicalPrice * (100 / acidChemicalConcentration)) / EQ_ACID[acidName]) *
      (acidChemicalConsumption / resinCapacity) +
    CrossRegFactor *
      ((baseChemicalPrice * (100 / baseChemicalConcentration)) / EQ_BASE[baseName]) *
      (acidChemicalConsumption / resinCapacity);

  return roundNumber(nonRoundedValue, 4);
};

const calcAnionChemical = ({
  acidName,
  baseName,
  crossRegeneration,
  baseChemicalConsumption,
  resinCapacity,
  acidChemicalPrice,
  acidChemicalConcentration,
  baseChemicalPrice,
  baseChemicalConcentration,
}: CalcChemicalInput): number => {
  const CrossRegFactor = crossRegeneration === 0 ? 0 : 1 / Math.abs(crossRegeneration);

  const nonRoundedValue =
    (1 + CrossRegFactor) *
      ((baseChemicalPrice * (100 / baseChemicalConcentration)) / EQ_BASE[baseName]) *
      (baseChemicalConsumption / resinCapacity) +
    CrossRegFactor *
      ((acidChemicalPrice * (100 / acidChemicalConcentration)) / EQ_ACID[acidName]) *
      (baseChemicalConsumption / resinCapacity);

  return roundNumber(nonRoundedValue, 4);
};

export default {
  calcCationChemical,
  calcAnionChemical,
};
