import React, { ReactElement, ReactNode } from 'react';
import ReactPDF, { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Scenario } from 'scenarios/ScenarioType';
import PDFValueHighlight from './PDFValueHighlight';

const useStyles = (theme: Theme): ReactPDF.Styles =>
  StyleSheet.create({
    h1: {
      fontSize: 18,
      lineHeight: 1.6,
      marginBottom: theme.spacing(2),
    },
    h2: {
      fontSize: 16,
      lineHeight: 1.4,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    h3: {
      fontSize: 14,
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    h4: {
      fontSize: 12,
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      height: '80px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    logo: {
      width: '130px',
      height: '39px',
    },
    headerText: {
      color: theme.palette.common.white,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.43,
    },
    body: {
      flexGrow: 1,
      paddingHorizontal: theme.spacing(4),
      color: fade(theme.palette.common.black, 0.8),
      fontSize: 10,
    },
    gridRow: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 10,
      paddingVertical: theme.spacing(0.5),
    },
    gridItem: {
      flex: 1,
      paddingRight: theme.spacing(1),
      justifyContent: 'center',
    },
    tableHeading: {
      fontSize: 9,
      marginBottom: theme.spacing(0.5),
    },
    spacer: {
      marginVertical: theme.spacing(1),
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    DEChartImage: {
      height: '400px',
      width: '400px',
    },
    savingsChartImage: {
      height: '400px',
      width: '400px',
    },
  });

const renderIfNotUndefined =
  (step: number, values: any[]) =>
  (Component: ReactElement): ReactElement => {
    if (values.findIndex((val) => val === undefined) !== -1) {
      return (
        <View>
          <Text style={{ color: '#BC0000' }}>You choose to export before completing step {`${step}`}</Text>
        </View>
      );
    }

    return Component;
  };

const PDFDocument = ({
  theme,
  currentScenario,
  calculationResult,
  DEChartImage,
  SavingsChartImage,
  currency,
}: {
  theme: Theme;
  currentScenario: Scenario;
  calculationResult: Record<string, any>;
  DEChartImage: string;
  SavingsChartImage: string;
  currency: string;
}): ReactElement => {
  const styles = useStyles(theme);

  const PageWithHeader = ({ children, ...restProps }: { children: ReactNode } & ReactPDF.PageProps): ReactElement => (
    <Page size="A4" {...restProps}>
      <View fixed style={styles.header}>
        <View>
          <Image src="/nz_logo_white.svg" style={styles.logo} />
          <Text style={styles.headerText}>Liquefaction Lab</Text>
        </View>

        <Text style={styles.headerText} fixed render={({ pageNumber, totalPages }): string => `${pageNumber} / ${totalPages}`} />
      </View>
      <View style={styles.body}>{children}</View>
    </Page>
  );

  return (
    <Document>
      <PageWithHeader>
        <View>
          <Text style={styles.h1}>{currentScenario.title}</Text>
          <Text style={styles.h2}>1. Reference case</Text>
        </View>
        {renderIfNotUndefined(1, [
          currentScenario.title,
          currentScenario.data.refEnzymeName,
          currentScenario.data.newEnzymeName,
          currentScenario.data.refEnzymePrice,
          currentScenario.data.newEnzymePrice,
          currentScenario.data.refEnzymeDosage,
          currentScenario.data.newEnzymeDosage,
          currentScenario.data.refEnzymepH,
          currentScenario.data.newEnzymepH,
          currentScenario.data.refEnzymeCalcium,
          currentScenario.data.newEnzymeCalcium,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Enzyme type </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeName} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Price </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymePrice} unit={`${currency} / kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymePrice} unit={`${currency} / kg`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Dosage </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeDosage} unit="kg / tDS" decimals={4} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeDosage} unit="kg / tDS" decimals={4} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>pH </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymepH} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymepH} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Calcium pph added </Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.refEnzymeCalcium} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.newEnzymeCalcium} />
              </View>
            </View>
          </>
        )}

        {DEChartImage && (
          <View style={styles.imageWrapper}>
            <Image src={DEChartImage} style={styles.DEChartImage} />
          </View>
        )}
      </PageWithHeader>
      <PageWithHeader>
        <View>
          <Text style={styles.h2}>2. Verify liquefaction & Ion exchange details</Text>
          <Text style={styles.h3}>Process</Text>
        </View>
        {renderIfNotUndefined(2, [
          currentScenario.data.plantCapacity,
          currentScenario.data.slurryLiquidpH,
          currentScenario.data.DSSlurryLiq,
          calculationResult.starchSlurryDensity,
          currentScenario.data.saccharificationpH,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Plant Capacity</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.plantCapacity} unit="tDS starch / day" />
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
            </View>

            <View style={styles.spacer} />

            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>pH</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>DS</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Density</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Starch slurry</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.slurryLiquidpH} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.DSSlurryLiq} unit="%" />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight highlight value={calculationResult.starchSlurryDensity} unit="kg/l" decimals={4} />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Saccharification</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.saccharificationpH} />
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
            </View>
          </>
        )}

        <View style={styles.spacer} />

        <View>
          <Text style={styles.h3}>Liquefaction chemicals</Text>
        </View>
        {renderIfNotUndefined(2, [
          currentScenario.data.baseChemicalName,
          currentScenario.data.baseChemicalPrice,
          currentScenario.data.baseChemicalConcentration,
          currentScenario.data.acidChemicalName,
          currentScenario.data.acidChemicalPrice,
          currentScenario.data.acidChemicalConcentration,
          currentScenario.data.calciumSourceName,
          currentScenario.data.calciumPrice,
          currentScenario.data.calciumConcentration,
          calculationResult.calciumPhAdj.ref,
          calculationResult.calciumPhAdj.new,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Chemical type</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Price</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Concentration</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Base</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.baseChemicalName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.baseChemicalPrice} unit={`${currency} / kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.baseChemicalConcentration} unit="% (w/w)" />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Acid</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.acidChemicalName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.acidChemicalPrice} unit={`${currency} / kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.acidChemicalConcentration} unit="% (w/w)" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Calcium</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.calciumSourceName} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.calciumPrice} unit={`${currency} / kg`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.calciumConcentration} unit="% (w/w)" />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />

              <View style={styles.gridItem}>
                <Text>Reference</Text>
              </View>
              <View style={styles.gridItem}>
                <Text>New solution</Text>
              </View>
              <View style={styles.gridItem} />
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Calcium ppm from pH adj.</Text>
              </View>

              <View style={styles.gridItem}>
                <PDFValueHighlight highlight value={calculationResult.calciumPhAdj.ref} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight highlight value={calculationResult.calciumPhAdj.new} />
              </View>
              <View style={styles.gridItem} />
            </View>
          </>
        )}

        <View style={styles.spacer} />

        <View>
          <Text style={styles.h3}>Slurry Titration</Text>
        </View>
        {renderIfNotUndefined(2, [
          currentScenario.data.titrationCoefficientA,
          currentScenario.data.titrationCoefficientB,
          currentScenario.data.titrationCoefficientC,
          currentScenario.data.titrationCoefficientD,
          currentScenario.data.titrationCoefficientE,
          currentScenario.data.DSSlurrySample,
        ])(
          <>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>A</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>B</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>C</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>D</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>E</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Coefficients</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.titrationCoefficientA} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.titrationCoefficientB} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.titrationCoefficientC} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.titrationCoefficientD} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.titrationCoefficientE} />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>DS slurry sample</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={currentScenario.data.DSSlurrySample} unit="%" />
              </View>
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
              <View style={styles.gridItem} />
            </View>
          </>
        )}

        <View style={styles.spacer} />

        <View>
          <Text style={styles.h3}>Evaporation</Text>
        </View>
        {currentScenario.data.evaporatorType === 'MVR'
          ? renderIfNotUndefined(2, [currentScenario.data.electricityConsumption, currentScenario.data.electricityPrice])(
              <>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Evaporator type</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Effects</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Electricity consumption</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Electricity price</Text>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.evaporatorType} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={1} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.electricityConsumption} unit="kWh / t evap" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.electricityPrice} unit={`${currency} / kWh`} />
                  </View>
                </View>
              </>
            )
          : renderIfNotUndefined(2, [
              currentScenario.data.evaporatorType,
              currentScenario.data.effects,
              calculationResult.steamConsumption,
              currentScenario.data.steamPrice,
            ])(
              <>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Evaporator type</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Effects</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>T steam / t evap</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <Text style={styles.tableHeading}>Steam price</Text>
                  </View>
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.evaporatorType} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.effects} />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={calculationResult.steamConsumption} unit="t steam / t evap" />
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.steamPrice} unit={`${currency} / t steam`} />
                  </View>
                </View>
              </>
            )}
      </PageWithHeader>
      <PageWithHeader>
        <View>
          <Text style={styles.h3}>IX Input data</Text>
        </View>
        {renderIfNotUndefined(2, [
          currentScenario.data.catOperation,
          currentScenario.data.anOperation,
          currentScenario.data.catResinCap,
          currentScenario.data.anResinCap,
          currentScenario.data.catResinLife,
          currentScenario.data.anResinLife,
          currentScenario.data.catResinPrice,
          currentScenario.data.anResinPrice,
          currentScenario.data.catRegChem,
          currentScenario.data.anRegChem,
          currentScenario.data.catChemConcentration,
          currentScenario.data.anChemConcentration,
          currentScenario.data.catChemPrice,
          currentScenario.data.anChemPrice,
          currentScenario.data.catChemConsumption,
          currentScenario.data.anChemConsumption,
          currentScenario.data.catWater,
          currentScenario.data.anWater,
          currentScenario.data.catSWDilution,
          currentScenario.data.anSWDilution,
          currentScenario.data.catWasteWater,
          currentScenario.data.anWasteWater,
          currentScenario.data.catCrossRegen,
          currentScenario.data.anCrossRegen,
          currentScenario.data.waterPrice,
          currentScenario.data.wasteWaterCost,
        ])(
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <View style={styles.gridRow}>
                <View style={styles.gridItem} />
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cation</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Anion</Text>
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Operation</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catOperation} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anOperation} />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Resin capacity</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catResinCap} unit="eq / l resin" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anResinCap} unit="eq / l resin" />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Resin lieftime</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catResinLife} unit="cycles" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anResinLife} unit="cycles" />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Resin price</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catResinPrice} unit={`${currency} / l resin`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anResinPrice} unit={`${currency} / l resin`} />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Regeneration chemical</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catRegChem} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anRegChem} />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Chemical concentration</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catChemConcentration} unit="% (w/w)" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anChemConcentration} unit="% (w/w)" />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Chemical price</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catChemPrice} unit={`${currency} / kg`} />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anChemPrice} unit={`${currency} / kg`} />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Chemical consumption</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catChemConsumption} unit="eq / l resin" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anChemConsumption} unit="eq / l resin" />
                </View>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <View style={styles.gridRow}>
                <View style={styles.gridItem} />
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Cation</Text>
                </View>
                <View style={styles.gridItem}>
                  <Text style={styles.tableHeading}>Anion</Text>
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Water</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catWater} unit="BV / reg." />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anWater} unit="BV / reg." />
                </View>
              </View>

              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Sweet water dilution</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catSWDilution} unit="BV / reg." />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anSWDilution} unit="BV / reg." />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Waste water</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catWasteWater} unit="BV / reg." />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anWasteWater} unit="BV / reg." />
                </View>
              </View>
              <View style={styles.gridRow}>
                <View style={styles.gridItem}>
                  <Text>Cross generation</Text>
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.catCrossRegen} unit="cycles" />
                </View>
                <View style={styles.gridItem}>
                  <PDFValueHighlight value={currentScenario.data.anCrossRegen} unit="cycles" />
                </View>
              </View>

              <View style={{ marginTop: '16px' }}>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Water price</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.waterPrice} unit={`${currency} / m³`} />
                  </View>
                  <View style={styles.gridItem} />
                </View>
                <View style={styles.gridRow}>
                  <View style={styles.gridItem}>
                    <Text>Waste water cost</Text>
                  </View>
                  <View style={styles.gridItem}>
                    <PDFValueHighlight value={currentScenario.data.wasteWaterCost} unit={`${currency} / m³`} />
                  </View>
                  <View style={styles.gridItem} />
                </View>
              </View>
            </View>
          </View>
        )}
      </PageWithHeader>
      <PageWithHeader>
        <View>
          <Text style={styles.h2}>3. View your savings</Text>
        </View>
        <View>
          <Text style={styles.h3}>Summary</Text>
          <Text>
            {/* eslint-disable-next-line max-len */}
            {`Switching from the reference ${currentScenario.data.refEnzymeName} to ${currentScenario.data.newEnzymeName} will result in the following savings:`}
          </Text>
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.yearlyTotals} unit={`${currency} / year`} highlight />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.dailyTotals} unit={`${currency} / day`} highlight />
          </View>
          <View style={styles.gridItem} />
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.savingsPerStarch} unit={`${currency} / tDS`} highlight />
          </View>
          <View style={styles.gridItem} />
          <View style={styles.gridItem} />
        </View>

        <View style={styles.spacer} />

        <View>
          <Text style={styles.h3}>Saving detail</Text>
        </View>

        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <Text>Reference</Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.refEnzymeName} />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.refEnzymeDosage} unit="kg / tDS" />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.refEnzymePrice} unit={`${currency} / kg`} />
          </View>
          <View style={styles.gridItem} />
        </View>
        <View style={styles.gridRow}>
          <View style={styles.gridItem}>
            <Text>New solution</Text>
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.newEnzymeName} />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.newEnzymeDosage} unit="kg / tDS" />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={currentScenario.data.newEnzymePrice} unit={`${currency} / kg`} />
          </View>
          <View style={styles.gridItem}>
            <PDFValueHighlight value={calculationResult.enzymeSavings} highlight unit={`${currency} / day`} />
          </View>
        </View>

        <View style={styles.spacer} />

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.h4}>Liquefaction chemical savings</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.h4}>Ion exchange savings</Text>
          </View>
          <View style={{ flex: 1 }} />
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Liq. Calcium</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.liqCalciumSavings} unit={`${currency} / day`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Liq. Base</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.liqBaseSavings} unit={`${currency} / day`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Liq. Base</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.liqAcidSavings} unit={`${currency} / day`} />
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>IX Resins</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.ixResinSavings} unit={`${currency} / day`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>IX Chemicals</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.ixChemSavings} unit={`${currency} / day`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>IX Waste water</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.ixWasteWaterSavings} unit={`${currency} / day`} />
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>IX Water</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.ixWaterSavings} unit={`${currency} / day`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>IX Sweet water evaporation</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.ixSweetWaterSavings} unit={`${currency} / day`} />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.spacer} />

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <Text style={styles.h4}>Regeneration costs</Text>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Cation</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Anion</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Chemical</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.cation.chemical} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.anion.chemical} unit={`${currency} / eq`} />
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Waste water</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.cation.wasteWater} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.anion.wasteWater} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Water</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.cation.water} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.anion.water} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Resin</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.cation.resin} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.anion.resin} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Sweet water evaporation</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight
                  value={calculationResult.regenerationCosts.cation.sweetWaterEvaporation}
                  unit={`${currency} / eq`}
                />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight
                  value={calculationResult.regenerationCosts.anion.sweetWaterEvaporation}
                  unit={`${currency} / eq`}
                />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Total</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.cation.total} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.regenerationCosts.anion.total} unit={`${currency} / eq`} />
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={styles.h4}>Reduction in IX load from liquefaction</Text>
            <View style={styles.gridRow}>
              <View style={styles.gridItem} />
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Cation</Text>
              </View>
              <View style={styles.gridItem}>
                <Text style={styles.tableHeading}>Anion</Text>
              </View>
            </View>

            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Calcium</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.calciumReduction} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.calciumReduction} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Base</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.chemicalReduction} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={0} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Acid</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={0} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.chemicalReduction} unit={`${currency} / eq`} />
              </View>
            </View>
            <View style={styles.gridRow}>
              <View style={styles.gridItem}>
                <Text>Total</Text>
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.totalReduction} unit={`${currency} / eq`} />
              </View>
              <View style={styles.gridItem}>
                <PDFValueHighlight value={calculationResult.totalReduction} unit={`${currency} / eq`} />
              </View>
            </View>
          </View>
        </View>
      </PageWithHeader>
      <PageWithHeader>
        {SavingsChartImage && (
          <View style={styles.imageWrapper}>
            <Image src={SavingsChartImage} style={styles.savingsChartImage} />
          </View>
        )}
      </PageWithHeader>
    </Document>
  );
};

export default PDFDocument;
