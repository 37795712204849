import roundNumber from 'utils/roundNumber';

export type Effects = '1' | '2' | '3' | '4+' | undefined;

export type ThermalVaporRecompressionInput = {
  effects: Effects;
  steamPrice: number;
};

const getSteamConsumption = (effects: Effects): number => {
  switch (effects) {
    case '1':
      return 0.5;
    case '2':
      return 0.33;
    case '3':
      return 0.25;
    case '4+':
      return 0.2;
    default:
      return 0;
  }
};

const thermalVaporRecompression = ({ effects, steamPrice }: ThermalVaporRecompressionInput): number => {
  const nonRoundedValue = steamPrice * getSteamConsumption(effects);
  return roundNumber(nonRoundedValue, 2);
};

export type MechanicalVaporRecompressionInput = {
  electricityPrice: number;
  electricityConsumption: number;
};

const mechanicalVaporRecompression = ({
  electricityPrice,
  electricityConsumption,
}: MechanicalVaporRecompressionInput): number => {
  const nonRoundedValue = electricityPrice * electricityConsumption;
  return roundNumber(nonRoundedValue, 2);
};

export default {
  thermalVaporRecompression,
  mechanicalVaporRecompression,
};
