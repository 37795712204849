import roundNumber from 'utils/roundNumber';
import { Base } from 'calculation/types';

type CalcCalciumInput = {
  baseLiquid: Base;
  slurryLiquidpH: number;
  enzymeLiquidpH: number;
  DSSlurryLiq: number;
  DSSlurrySample: number;
  slurryTitrationRegressionCoefficients: {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
  };
};

const densSlurry = (DSSlurryLiq: number): number => roundNumber(0.00469 * DSSlurryLiq + 0.99335, 3);

const calcCalcium = (input: CalcCalciumInput): number => {
  const { baseLiquid, slurryLiquidpH, enzymeLiquidpH, DSSlurryLiq, DSSlurrySample, slurryTitrationRegressionCoefficients } =
    input;

  if (baseLiquid !== 'Ca(OH)2' || slurryLiquidpH > enzymeLiquidpH) {
    return 0;
  }
  return roundNumber(
    (20 / densSlurry(DSSlurryLiq)) *
      (DSSlurryLiq / DSSlurrySample) *
      (slurryTitrationRegressionCoefficients.e * (enzymeLiquidpH ** 4 - slurryLiquidpH ** 4) +
        slurryTitrationRegressionCoefficients.d * (enzymeLiquidpH ** 3 - slurryLiquidpH ** 3) +
        slurryTitrationRegressionCoefficients.c * (enzymeLiquidpH ** 2 - slurryLiquidpH ** 2) +
        slurryTitrationRegressionCoefficients.b * (enzymeLiquidpH - slurryLiquidpH)),
    0
  );
};

export default calcCalcium;
