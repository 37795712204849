import { EnzymeModel } from './EnzymeModel';
import DEMethods from './DEMethods';
import { EnzymeInput } from './EnzymeInput';
import isEnzymeInputValid from './EnzymeInputValidator';

const intercept = -14.9437;
const dose = 0.1184616;
const pHA = 2.1044144;
const pHcpHC = -4.340316;
const pHcpHcpHC = 3.0244517;
const calciumA = 0.0283641;
const calciumcpHC = -0.043187;
const timeA = 0.0923162;
const timecDoseC = 0.0006657;
const timecpHC = 0.0174694;
const timecTimeC = -0.000342;
const timecTimecDoseC = -0.00000575;
const enzymeC = 74.0796;
const pHC = 4.966;
const calciumC = 17.189;
const timeC = 84.4776;

const LPhera2H = (input: EnzymeInput, deNeo: number[]): number[] => {
  const simulation: number[] = Array(9);
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  simulation[1] = fehlingsB + fehlingsA * deNeo[1]; // 15 min.
  simulation[2] = fehlingsB + fehlingsA * deNeo[2]; // 30 min.
  simulation[3] = fehlingsB + fehlingsA * deNeo[3]; // 45 min.
  simulation[4] = fehlingsB + fehlingsA * deNeo[4]; // 60 min.
  simulation[5] = fehlingsB + fehlingsA * ((deNeo[4] + deNeo[5]) / 2); // 75 min.
  simulation[6] = fehlingsB + fehlingsA * deNeo[5]; // 90 min.
  simulation[7] = fehlingsB + fehlingsA * ((deNeo[5] + deNeo[6]) / 2); // 105 min.
  simulation[8] = fehlingsB + fehlingsA * deNeo[6]; // 120 min.

  // Estimate DE at time 0
  simulation[0] = simulation[1] - (simulation[2] - simulation[1]);

  return simulation;
};

const LPhera4H = (input: EnzymeInput, deNeo: number[]): number[] => {
  const simulation: number[] = Array(9);
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  simulation[1] = fehlingsB + fehlingsA * deNeo[1]; // 30
  simulation[2] = fehlingsB + fehlingsA * deNeo[2]; // 60
  simulation[3] = fehlingsB + fehlingsA * deNeo[3]; // 90
  simulation[4] = fehlingsB + fehlingsA * deNeo[4]; // 120
  simulation[5] = fehlingsB + fehlingsA * deNeo[5]; // 150
  simulation[6] = fehlingsB + fehlingsA * deNeo[6]; // 180 min.

  // Estimate DE at time 0
  simulation[0] = simulation[1] - (simulation[2] - simulation[1]);

  // Extrapolation of model to 4 hours
  simulation[7] = simulation[6] + (simulation[6] - simulation[5]) ** 2 / (simulation[5] - simulation[4]); // 210 min.
  simulation[8] = simulation[7] + (simulation[7] - simulation[6]) ** 2 / (simulation[6] - simulation[5]); // 240 min.

  return simulation;
};
const LPhera =
  (isDX: boolean): EnzymeModel =>
  (input): number[] => {
    if (!isEnzymeInputValid(input)) {
      return Array(9).fill(0);
    }
    const activity = isDX ? 310 : 440;

    const enzymeModel = input.dosage * activity - enzymeC;
    const phModel = input.pH - pHC;
    const calciumModel = input.calcium - calciumC;

    const doseNumber = dose * input.dosage * activity;
    const pHNumber = pHA * input.pH;
    const pHcpHcNumber = pHcpHC * phModel ** 2;
    const pHcpHcpHcNumber = pHcpHcpHC * phModel ** 3;
    const calciumNumber = calciumA * input.calcium;
    const calciumcpHcNumber = calciumcpHC * phModel * calciumModel;
    const sum = intercept + doseNumber + pHNumber + pHcpHcNumber + pHcpHcpHcNumber + calciumNumber + calciumcpHcNumber;

    const deNeo: number[] = Array(9);
    const times: number[] =
      input.timeStep === 15 ? [0, 15, 30, 45, 60, 90, 120, 150, 180] : [0, 30, 60, 90, 120, 150, 180, 210, 240];
    // eslint-disable-next-line no-plusplus
    for (let timeI = 1; timeI <= 8; timeI++) {
      const time = times[timeI];
      const timeCenter = time - timeC;
      const timeF = time * timeA;
      const timecFDosec = timecDoseC * timeCenter * enzymeModel;
      const timecFpHc = timecpHC * timeCenter * phModel;
      const timecFTimec = timecTimeC * timeCenter ** 2;
      const timecFTimecDosec = timecTimecDoseC * timeCenter ** 2 * enzymeModel;
      const sumF = timeF + timecFDosec + timecFpHc + timecFTimec + timecFTimecDosec;
      deNeo[timeI] = sum + sumF;
    }

    return input.timeStep === 15 ? LPhera2H(input, deNeo) : LPhera4H(input, deNeo);
  };

export default LPhera;
