import { Region, PricePreset } from './ScenarioType';

export default (region?: Region): PricePreset => {
  switch (region) {
    case Region.China:
      return {
        HCl: 0.876,
        H2SO4: 2.19,
        NaOH: 2.75,
        Na2CO3: 3.13,
        'Ca(OH)2': 0.18,
        NH4OH: 0,
        CaCl2: 2.0,
        'CaCl2*2H2O': 1.8,
        NaHSO3: 2.4,
        'Mg(HSO3)2': 2.1,
        'MgSO4*7H2O': 1.5,
        catResin: 12,
        anResin: 24,
        steam: 150,
        electricity: 0.5,
        processWater: 5,
        wasteWater: 5,
        DMH: 4900,
        hydrol: 2100,
        DE95: 4000,
      };

    case Region.CWE:
    case Region.EE:
    case Region.MEA:
      return {
        HCl: 0.115,
        H2SO4: 0.29,
        NaOH: 0.355,
        Na2CO3: 0.4,
        'Ca(OH)2': 0.03,
        NH4OH: 0,
        CaCl2: 0.35,
        'CaCl2*2H2O': 0.3,
        NaHSO3: 0.4,
        'Mg(HSO3)2': 0.35,
        'MgSO4*7H2O': 0.25,
        catResin: 2,
        anResin: 4,
        steam: 20,
        electricity: 0.09,
        processWater: 1,
        wasteWater: 1,
        DMH: 643,
        hydrol: 275,
        DE95: 520,
      };

    case Region.India:
    case Region.JKA:
    case Region.LA:
    case Region.NA:
    case Region.SEA:
      return {
        HCl: 0.14,
        H2SO4: 0.35,
        NaOH: 0.44,
        Na2CO3: 0.5,
        'Ca(OH)2': 0.03,
        NH4OH: 0,
        CaCl2: 0.35,
        'CaCl2*2H2O': 0.3,
        NaHSO3: 0.4,
        'Mg(HSO3)2': 0.35,
        'MgSO4*7H2O': 0.25,
        catResin: 2,
        anResin: 4,
        steam: 20,
        electricity: 0.09,
        processWater: 1,
        wasteWater: 1,
        DMH: 797,
        hydrol: 340,
        DE95: 640,
      };

    default:
      return {
        HCl: undefined,
        H2SO4: undefined,
        NaOH: undefined,
        Na2CO3: undefined,
        'Ca(OH)2': undefined,
        NH4OH: undefined,
        CaCl2: undefined,
        'CaCl2*2H2O': undefined,
        NaHSO3: undefined,
        'Mg(HSO3)2': undefined,
        'MgSO4*7H2O': undefined,
        catResin: undefined,
        anResin: undefined,
        steam: undefined,
        electricity: undefined,
        processWater: undefined,
        wasteWater: undefined,
        DMH: undefined,
        hydrol: undefined,
        DE95: undefined,
      };
  }
};
