import { Scenario, ScenarioData } from './ScenarioType';

const scenarioData: ScenarioData = {
  // Enzymes
  newEnzymeName: undefined,
  newEnzymePrice: undefined,
  newEnzymeDosage: undefined,
  newEnzymepH: undefined,
  newEnzymeCalcium: undefined,
  refEnzymeName: undefined,
  refEnzymePrice: undefined,
  refEnzymeDosage: undefined,
  refEnzymepH: undefined,
  refEnzymeCalcium: undefined,
  timeStep: 15,
  DEMethod: 'Fehling',
  // Competitor DE Data
  competitorDEMethod: 'Fehling',
  liquefactionDE: undefined,
  liquefactionTime: undefined,
  // Chemicals
  baseChemicalName: undefined,
  baseChemicalPrice: undefined,
  baseChemicalConcentration: undefined,
  acidChemicalName: undefined,
  acidChemicalPrice: undefined,
  acidChemicalConcentration: undefined,
  calciumSourceName: undefined,
  calciumPrice: undefined,
  calciumConcentration: undefined,
  // IonExchangeAnionInput
  anOperation: undefined,
  anResinCap: 0.8,
  anResinLife: 450,
  anResinPrice: undefined,
  anRegChem: undefined,
  anChemConcentration: undefined,
  anChemPrice: undefined,
  anChemConsumption: 1.5,
  anWater: 2,
  anSWDilution: 2,
  anWasteWater: 6,
  anCrossRegen: 0,
  // IonExchangeCationInput
  catOperation: undefined,
  catResinCap: 1.2,
  catResinLife: 700,
  catResinPrice: undefined,
  catRegChem: undefined,
  catChemConcentration: undefined,
  catChemPrice: undefined,
  catChemConsumption: 2.5,
  catWater: 2,
  catSWDilution: 2,
  catWasteWater: 6,
  catCrossRegen: 10,
  // IonExchangeEvaporationInput
  evaporatorType: 'MVR',
  electricityPrice: 0.09,
  electricityConsumption: 35,
  effects: '4+',
  steamPrice: 20,
  // IonExchangeWaterInput
  waterPrice: undefined,
  wasteWaterCost: undefined,
  // SlurryTitration
  titrationCoefficientA: 1.785,
  titrationCoefficientB: -2.337,
  titrationCoefficientC: 0.449,
  titrationCoefficientD: 0,
  titrationCoefficientE: 0,
  DSSlurrySample: 35,
  // StarchSlurry
  plantCapacity: undefined,
  DSSlurryLiq: 35,
  slurryLiquidpH: 4.5,
  saccharificationpH: 4.3,
};

const scenario: Scenario = {
  title: 'New Scenario',
  data: scenarioData,
  creatorName: undefined,
  updatedAt: Date.now(),
  region: undefined,
};

export default scenario;
