import React, { ReactElement } from 'react';
import TotalSavings from 'sections/TotalSavings';
import PageTitle from 'components/PageTitle';
import getAtomState from 'state/atomState';
import { useRecoilValue } from 'recoil';
import DividerWithSpacing from 'components/DividerWithSpacing';
import SavingsDetail from 'sections/SavingsDetail';
import DetailedSavingsOnIX from 'sections/DetailedSavingsOnIX';
import { Box } from '@material-ui/core';
import MarketSelector from 'components/dialogs/MarketSelector';

const Savings = (): ReactElement => {
  const newEnzymeState = getAtomState<string>('newEnzymeName');
  const refEnzymeState = getAtomState<string>('refEnzymeName');
  const newEnzyme = useRecoilValue(newEnzymeState);
  const refEnzyme = useRecoilValue(refEnzymeState);

  return (
    <>
      <PageTitle
        title="3. View your savings"
        subTitle={`Switching from ${refEnzyme} to ${newEnzyme} will result in the following savings.`}
      />
      <MarketSelector />
      <TotalSavings />
      <DividerWithSpacing />
      <SavingsDetail />
      <DividerWithSpacing />
      <DetailedSavingsOnIX />
      <Box my={6} />
    </>
  );
};

export default Savings;
