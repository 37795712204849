import React, { ReactNode, ReactElement, useRef, useEffect } from 'react';
import { Container, makeStyles, Box, RootRef } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { useLocation } from 'react-router-dom';

import { colors } from '@novozymes/components';
import { ScenarioParamsFetcher } from 'scenarios/ScenarioGetter';
import { FOOTER_HEIGHT, HEADER_HEIGHT, PROCESS_HEADER_HEIGHT } from 'components/Constants';
import Header from './Header';
import ProcessHeader from './ProcessHeader';
import BottomNav from './BottomNav';
import ErrorBanner from './ErrorBanner';

interface PageProps {
  children: NonNullable<ReactNode>;
  hideProcessHeader?: boolean;
  hideFooter?: boolean;
  isHomePage?: boolean;
}

const useStyles = makeStyles(() => ({
  root: ({ isHomePage }: PageProps): CreateCSSProperties => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: isHomePage ? colors.bg2 : colors.bg1,
  }),
  scrollContainer: ({ hideProcessHeader }: PageProps): CreateCSSProperties => ({
    height: `calc(100vh  - ${HEADER_HEIGHT} - ${hideProcessHeader ? '0px' : PROCESS_HEADER_HEIGHT}) `,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: FOOTER_HEIGHT,
  }),
}));
const Page = (props: PageProps): ReactElement => {
  const { children, hideProcessHeader = false, hideFooter = false, isHomePage = false } = props;
  const classes = useStyles(props);
  const location = useLocation();

  const scrollContainerRef = useRef<any>();

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <ScenarioParamsFetcher>
      <Box className={classes.root}>
        <ErrorBanner />
        <Header isHomePage={isHomePage} />
        {!hideProcessHeader && <ProcessHeader />}
        <RootRef rootRef={scrollContainerRef}>
          <Box className={classes.scrollContainer}>
            <Container>{children}</Container>
          </Box>
        </RootRef>
        {!hideFooter && <BottomNav />}
      </Box>
    </ScenarioParamsFetcher>
  );
};

export default Page;
