import React, { ReactElement, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Button } from '@novozymes/components';

const useStyles = makeStyles(() => ({
  canvas: {
    height: 'calc(100vh - 250px)',
    overflowY: 'scroll',
  },
}));

const DocumentViewer = (): ReactElement => {
  const classes = useStyles();
  const { name } = useParams<{ name: string }>();

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  // eslint-disable-next-line no-shadow
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const nextDisabled = !!(numPages && pageNumber >= numPages);
  const prevDisabled = !!(numPages && pageNumber <= 1);

  const handleNextClick = useCallback(() => {
    if (!nextDisabled) {
      setPageNumber(pageNumber + 1);
    }
  }, [nextDisabled, pageNumber]);

  const handlePrevClick = useCallback(() => {
    if (!prevDisabled) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber, prevDisabled]);

  return (
    <Box>
      <Box>
        <Button small onClick={handlePrevClick} disabled={prevDisabled}>
          Previous
        </Button>
        <Button small onClick={handleNextClick} disabled={nextDisabled}>
          Next
        </Button>
      </Box>
      <Document file={`/doc/${name}.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </Box>
  );
};

export default DocumentViewer;
