import React, { ReactElement, ReactNode } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

type GridTableProps = {
  columns: {
    heading?: string;
    data: any[] | ReactNode;
  }[];
};

const GridTable = ({ columns }: GridTableProps): ReactElement => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {columns.map((column, colIndex) =>
        !Array.isArray(column.data) ? (
          <Grid item xs={2}>
            {column.data}
          </Grid>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={`column_${colIndex}_${column.heading}`} item xs={2}>
            <Grid container spacing={2}>
              {column.heading ? (
                <Grid item xs={12} className={classes.heading}>
                  {column.heading}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box height={20} />
                </Grid>
              )}
              {(column.data as Array<any>).map((dataEntry: any, dataIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={`column_${colIndex}_${column.heading}_${dataIndex}`} item xs={12}>
                  <Box height={41} className={classes.label}>
                    {dataEntry}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default GridTable;
