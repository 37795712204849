/* eslint-disable class-methods-use-this */
import { EnzymeModel } from './EnzymeModel';
import DEMethods from './DEMethods';
import { EnzymeInput } from './EnzymeInput';
import isEnzymeInputValid from './EnzymeInputValidator';

const LiquozymeSupra2H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (-99.352407 -
        0.2153299 * 135 * input.dosage +
        38.6736769 * input.pH +
        0.09000694 * input.calcium -
        0.2048978 * time +
        135 * input.dosage * input.pH * 0.046395 -
        input.pH * input.pH * 3.8014311 -
        0.0017421 * input.calcium * input.calcium +
        0.00138647 * 135 * input.dosage * time +
        0.04708692 * time * input.pH -
        0.0002118 * time * time)
  );
};

const LiquozymeSupra4H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (0 +
        -3.0654902 * (time + 10) +
        -0.5912901 * input.calcium +
        -168.76189 * input.dosage +
        57.46394 * input.pH +
        -0.00006770430055021 * (time + 10) ** 2 +
        -19.625888 * input.pH ** 2 +
        0.1085475 * input.calcium * input.pH +
        33.000932 * input.dosage * input.pH +
        1.09451429426 * (time + 10) * input.pH +
        0.1794806189548 * (time + 10) * input.dosage +
        0.0003137435780995 * (time + 10) * input.calcium +
        -0.09687550117689 * (time + 10) * input.pH ** 2 +
        1.650402844845 * input.pH ** 3)
  );
};

const LiquozymeSupra: EnzymeModel = (input) => {
  const simulation: number[] = Array(9).fill(0);

  if (!isEnzymeInputValid(input)) {
    return simulation;
  }

  const model = input.timeStep === 15 ? LiquozymeSupra2H : LiquozymeSupra4H;

  for (let timeI = 0; timeI < simulation.length; timeI += 1) {
    const time = timeI * input.timeStep;

    simulation[timeI] = model(input, time);
  }

  return simulation;
};
export default LiquozymeSupra;
