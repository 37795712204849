import { atom, RecoilState, selector } from 'recoil';
import defaultScenario from 'scenarios/defaultScenario';
import { Scenario, ScenarioDataKey, ScenarioErrors } from 'scenarios/ScenarioType';
import getScenarioDataUpdater from './dependantState';

export const remoteScenarioState = atom<Scenario | undefined>({ key: 'remoteScenario', default: undefined });
export const activeScenarioState = atom<Scenario>({ key: 'activeScenario', default: defaultScenario });
export const scenarioErrorState = atom<ScenarioErrors>({ key: 'scenarioErrors', default: {} });

type FieldStatesType = Partial<{ [key in ScenarioDataKey]: RecoilState<any> }>;
export const fieldStates: FieldStatesType = {};

function getAtomState<T>(key: ScenarioDataKey): RecoilState<T | undefined> {
  const value = fieldStates[key] as RecoilState<T | undefined>;
  if (!value) {
    const fieldState: RecoilState<T | undefined> = selector({
      key,
      get: ({ get }) => {
        const activeScenario = get(activeScenarioState);
        return activeScenario.data[key] as unknown as T | undefined;
      },
      set: ({ get, set }, newValue) => {
        const activeScenario = get(activeScenarioState);
        const scenarioUpdater = getScenarioDataUpdater(key);
        const updatedScenario: Scenario = scenarioUpdater({
          ...activeScenario,
          data: { ...activeScenario.data, [key]: newValue },
        });
        set(activeScenarioState, updatedScenario);
      },
    });
    fieldStates[key] = fieldState;
    return fieldState;
  }
  return value;
}
export default getAtomState;
