import React, { ReactElement } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import {
  ionExchangeAnChem,
  ionExchangeCatChem,
  ionExchangeAnWasteWater,
  ionExchangeCatWasteWater,
  ionExchangeAnWater,
  ionExchangeCatWater,
  ionExchangeCatResin,
  ionExchangeAnResin,
  ionExchangeCatSweetWater,
  ionExchangeAnSweetWater,
  ionExchangeCatTotal,
  ionExchangeAnTotal,
  currency,
} from 'state/getDerivedValues';
import ValueHighlight from 'components/ValueHighlight';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const IonExchangeRegenOutput = (): ReactElement => {
  const classes = useStyles();
  const activeCurrency = useRecoilValue(currency);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} />
      <Grid item xs={4} className={classes.heading}>
        Cation
      </Grid>
      <Grid item xs={4} className={classes.heading}>
        Anion
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Chemical
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatChem)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnChem)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Waste water
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatWasteWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnWasteWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Water
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Resin
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatResin)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnResin)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Sweet water evaporation
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatSweetWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnSweetWater)} unit={`${activeCurrency} / eq`} />
      </Grid>
      <Grid item xs={4} className={classes.label}>
        Total
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeCatTotal)} unit={`${activeCurrency} / eq`} highlight />
      </Grid>
      <Grid item xs={4}>
        <ValueHighlight value={useRecoilValue(ionExchangeAnTotal)} unit={`${activeCurrency} / eq`} highlight />
      </Grid>
    </Grid>
  );
};

export default IonExchangeRegenOutput;
