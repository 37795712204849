/* eslint-disable no-mixed-operators */
import roundNumber from 'utils/roundNumber';

export type CalcReductionOfCalciumInput = {
  referenceCalciumDosage: number;
  newCalciumDosage: number;
  liquefactionCapacity: number;
  starchSlurryDS: number;
};

const KG_PER_EQ_OF_CALCIUM = 0.02; // kg / eq of Calcium

const calcReductionOfCalcium = ({
  referenceCalciumDosage,
  newCalciumDosage,
  liquefactionCapacity,
  starchSlurryDS,
}: CalcReductionOfCalciumInput): number =>
  roundNumber(
    (referenceCalciumDosage - newCalciumDosage) *
      10 ** -6 *
      ((liquefactionCapacity * 1000 * 100) / starchSlurryDS / KG_PER_EQ_OF_CALCIUM),
    0
  );

export type CalcReductionOfChemicalInput = {
  starchSlurryDS: number;
  starchSlurrySampleDS: number;
  liquefactionCapacity: number;
  newpH: number;
  referencepH: number;
  slurryTitrationRegressionCoefficients: {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
  };
};

const calcSlurryDensity = (starchSlurryDS: number): number => roundNumber(starchSlurryDS * 0.00469 + 0.99335, 3);

const calcReductionOfChemical = ({
  liquefactionCapacity,
  starchSlurryDS,
  starchSlurrySampleDS,
  slurryTitrationRegressionCoefficients,
  newpH,
  referencepH,
}: CalcReductionOfChemicalInput): number =>
  roundNumber(
    ((liquefactionCapacity * 100) / (starchSlurryDS * calcSlurryDensity(starchSlurryDS))) *
      (starchSlurryDS / starchSlurrySampleDS) *
      (slurryTitrationRegressionCoefficients.e * (referencepH ** 4 - newpH ** 4) +
        slurryTitrationRegressionCoefficients.d * (referencepH ** 3 - newpH ** 3) +
        slurryTitrationRegressionCoefficients.c * (referencepH ** 2 - newpH ** 2) +
        slurryTitrationRegressionCoefficients.b * (referencepH - newpH)),
    0
  );

export default {
  calcReductionOfCalcium,
  calcReductionOfChemical,
};
