import React, { ReactElement } from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import useInputWithState from 'state/useInputWithState';
import { Acid, Base, CalciumSource } from 'calculation/types';
import { useRecoilValue } from 'recoil';
import { phAdjustNewEnzyme, phAdjustRefEnzyme, currency } from 'state/getDerivedValues';
import ValueHighlight from 'components/ValueHighlight';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
  },
  heading: {
    fontSize: '16px',
  },
}));

const Chemicals = (): ReactElement => {
  const activeCurrency = useRecoilValue(currency);

  const [, baseChemicalNameInput] = useInputWithState<Base>({
    key: 'baseChemicalName',
    type: 'Select',
    helperText: 'Select base chemical',
    selectOptions: [
      {
        value: 'NaOH',
        label: 'NaOH',
      },
      {
        value: 'Na2CO3',
        label: 'Na2CO3',
      },
      {
        value: 'NH4OH',
        label: 'NH4OH',
      },
      {
        value: 'Ca(OH)2',
        label: 'Ca(OH)2',
      },
    ],
  });

  const [, baseChemicalPriceInput] = useInputWithState({
    key: 'baseChemicalPrice',
    unit: `${activeCurrency} / kg`,
  });
  const [, baseChemicalConcentrationInput] = useInputWithState({
    key: 'baseChemicalConcentration',
    unit: '% (w/w)',
  });

  const [, acidChemicalNameInput] = useInputWithState<Acid>({
    key: 'acidChemicalName',
    type: 'Select',
    helperText: 'Select acid chemical',
    selectOptions: [
      {
        value: 'HCl',
        label: 'HCl',
      },
      {
        value: 'H2SO4',
        label: 'H2SO4',
      },
    ],
  });

  const [, acidChemicalPriceInput] = useInputWithState({
    key: 'acidChemicalPrice',
    unit: `${activeCurrency} / kg`,
  });
  const [, acidChemicalConcentrationInput] = useInputWithState({
    key: 'acidChemicalConcentration',
    unit: '% (w/w)',
  });

  const [, calciumNameInput] = useInputWithState<CalciumSource>({
    key: 'calciumSourceName',
    type: 'Select',
    helperText: 'Select calcium source',
    selectOptions: [
      {
        value: 'CaCl2*2H2O',
        label: 'CaCl2*2H2O',
      },
      {
        value: 'CaCl2',
        label: 'CaCl2',
      },
    ],
  });

  const [, calciumPriceInput] = useInputWithState({
    key: 'calciumPrice',
    unit: `${activeCurrency} / kg`,
  });
  const [, calciumConcentrationInput] = useInputWithState({
    key: 'calciumConcentration',
    unit: '% (w/w)',
  });

  const phAdjustNewEnzymeValue = useRecoilValue(phAdjustNewEnzyme);
  const phAdjustRefEnzymeValue = useRecoilValue(phAdjustRefEnzyme);

  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">Liquefaction chemicals</Typography>
      <Grid container spacing={2}>
        <Grid item xs={2} />
        <Grid item xs={2} className={classes.heading}>
          Chemical type
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          Price
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          Concentration
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={2} className={classes.label}>
          Base
        </Grid>
        <Grid item xs={2}>
          {baseChemicalNameInput}
        </Grid>
        <Grid item xs={2}>
          {baseChemicalPriceInput}
        </Grid>
        <Grid item xs={2}>
          {baseChemicalConcentrationInput}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2} className={classes.label}>
          Acid
        </Grid>
        <Grid item xs={2}>
          {acidChemicalNameInput}
        </Grid>
        <Grid item xs={2}>
          {acidChemicalPriceInput}
        </Grid>
        <Grid item xs={2}>
          {acidChemicalConcentrationInput}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2} className={classes.label}>
          Calcium
        </Grid>
        <Grid item xs={2}>
          {calciumNameInput}
        </Grid>
        <Grid item xs={2}>
          {calciumPriceInput}
        </Grid>
        <Grid item xs={2}>
          {calciumConcentrationInput}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={2} />

        <Grid item xs={2} className={classes.heading}>
          <Box pt={1}>Reference</Box>
        </Grid>
        <Grid item xs={2} className={classes.heading}>
          <Box pt={1}>New solution</Box>
        </Grid>
        <Grid item xs={6} />

        <Grid item xs={2} className={classes.label}>
          Calcium ppm from pH adj.
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={phAdjustRefEnzymeValue} unit="pH adj." />
        </Grid>
        <Grid item xs={2}>
          <ValueHighlight value={phAdjustNewEnzymeValue} unit="pH adj." />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </>
  );
};

export default Chemicals;
