interface FitEnzymeDosageInput {
  targetDE?: number;
  enzymeDosageRange: { min: number; max: number };
  getEnzymeDE: (dosage: number) => number | undefined;
}

const fitEnzymeDosage = ({ targetDE, enzymeDosageRange, getEnzymeDE }: FitEnzymeDosageInput): number | undefined => {
  if (targetDE === undefined) {
    return undefined;
  }

  for (let dosage = enzymeDosageRange.min; dosage <= enzymeDosageRange.max; dosage += 0.0001) {
    const proposedDosageDE = getEnzymeDE(dosage);

    if (proposedDosageDE === undefined) {
      return undefined;
    }

    if (Math.abs(proposedDosageDE - targetDE) < 0.1) {
      return dosage;
    }
  }
  return undefined;
};

export default fitEnzymeDosage;
