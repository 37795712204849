import { EnzymeInput } from './EnzymeInput';

const isEnzymeInputValid = (input: EnzymeInput): boolean => {
  if (input.pH < 0 || input.dosage < 0 || input.calcium < 0) {
    return false;
  }

  if (input.timeStep !== 15 && input.timeStep !== 30) {
    return false;
  }

  return true;
};

export default isEnzymeInputValid;
