import { Base, Acid, CalciumSource } from 'calculation/types';
import { EvaporatorType } from 'calculation/ionExchange/calcWaterCost';
import { Effects } from 'calculation/ionExchange/sweetWaterEvaporation';
import { DEMethodType } from 'calculation/enzymeModels/EnzymeInput';

export enum Region {
  China = 'China',
  CWE = 'Central West Europe',
  EE = 'East Europe',
  India = 'India',
  JKA = 'Japan and Korea',
  LA = 'Latin America ',
  MEA = 'Middle East Africa',
  NA = 'North America',
  SEA = 'South East Asia',
}

export type TimeStep = 15 | 30;

export type PricePreset = {
  HCl?: number;
  H2SO4?: number;
  NaOH?: number;
  Na2CO3?: number;
  'Ca(OH)2'?: number;
  NH4OH?: number;
  CaCl2?: number;
  'CaCl2*2H2O'?: number;
  NaHSO3?: number;
  'Mg(HSO3)2'?: number;
  'MgSO4*7H2O'?: number;
  catResin?: number;
  anResin?: number;
  steam?: number;
  electricity?: number;
  processWater?: number;
  wasteWater?: number;
  DMH?: number;
  hydrol?: number;
  DE95?: number;
};

export type ConcPreset = {
  HCl?: number;
  H2SO4?: number;
  NaOH?: number;
  Na2CO3?: number;
  'Ca(OH)2'?: number;
  NH4OH?: number;
  CaCl2?: number;
  'CaCl2*2H2O'?: number;
  NaHSO3?: number;
  'Mg(HSO3)2'?: number;
  'MgSO4*7H2O'?: number;
  DMH?: number;
  hydrol?: number;
  DE95?: number;
};
export interface ScenarioData {
  newEnzymeName?: string;
  newEnzymePrice?: number;
  newEnzymeDosage?: number;
  newEnzymepH?: number;
  newEnzymeCalcium?: number;
  refEnzymeName?: string;
  refEnzymePrice?: number;
  refEnzymeDosage?: number;
  refEnzymepH?: number;
  refEnzymeCalcium?: number;
  timeStep?: TimeStep;
  competitorDEMethod?: DEMethodType;
  DEMethod?: DEMethodType;
  liquefactionDE?: number;
  liquefactionTime?: number;
  baseChemicalName?: Base;
  baseChemicalPrice?: number;
  baseChemicalConcentration?: number;
  acidChemicalName?: Acid;
  acidChemicalPrice?: number;
  acidChemicalConcentration?: number;
  calciumSourceName?: CalciumSource;
  calciumPrice?: number;
  calciumConcentration?: number;
  anOperation?: 'Co-current' | 'Counter current';
  anResinCap?: number;
  anResinLife?: number;
  anResinPrice?: number;
  anRegChem?: Base;
  anChemConcentration?: number;
  anChemPrice?: number;
  anChemConsumption?: number;
  anWater?: number;
  anSWDilution?: number;
  anWasteWater?: number;
  anCrossRegen?: number;
  catOperation?: 'Co-current' | 'Counter current';
  catResinCap?: number;
  catResinLife?: number;
  catResinPrice?: number;
  catRegChem?: Acid;
  catChemConcentration?: number;
  catChemPrice?: number;
  catChemConsumption?: number;
  catWater?: number;
  catSWDilution?: number;
  catWasteWater?: number;
  catCrossRegen?: number;
  evaporatorType?: EvaporatorType;
  electricityPrice?: number;
  electricityConsumption?: number;
  effects?: Effects;
  steamPrice?: number;
  waterPrice?: number;
  wasteWaterCost?: number;
  titrationCoefficientA?: number;
  titrationCoefficientB?: number;
  titrationCoefficientC?: number;
  titrationCoefficientD?: number;
  titrationCoefficientE?: number;
  DSSlurrySample?: number;
  plantCapacity?: number;
  DSSlurryLiq?: number;
  slurryLiquidpH?: number;
  saccharificationpH?: number;
}

export type ScenarioDataKey = keyof ScenarioData;

export type ScenarioErrors = Partial<Record<ScenarioDataKey, string>>;

export interface Scenario {
  title: string;
  creatorName?: string;
  id?: string;
  region?: Region;
  data: ScenarioData;
  updatedAt: number;
}

export interface ScenarioFromBackend {
  Title: string;
  ScenarioId: string;
  CreatorName: string;
  ScenarioData: ScenarioData;
  ScenarioRegion: Region;
  UpdatedAt: number;
}

export interface ScenarioParams {
  scenarioId?: string;
}
