/* eslint-disable no-mixed-operators */
import roundNumber from 'utils/roundNumber';
import { Acid, Base, CalciumSource } from 'calculation/types';

export type CalcLiqEnzymesDaySavingInput = {
  plantCapacity: number;
  dosageReferenceEnzyme: number;
  priceReferenceEnzyme: number;
  dosageNewEnzyme: number;
  priceNewEnzyme: number;
};

const calcLiqEnzymesDailySaving = ({
  plantCapacity,
  dosageReferenceEnzyme,
  priceReferenceEnzyme,
  dosageNewEnzyme,
  priceNewEnzyme,
}: CalcLiqEnzymesDaySavingInput): number => {
  const nonRoundedValue = plantCapacity * (dosageReferenceEnzyme * priceReferenceEnzyme - dosageNewEnzyme * priceNewEnzyme);
  return roundNumber(nonRoundedValue, 1);
};

export type CalcLiqCalciumDailySavingsInput = {
  plantCapacity: number;
  newCalciumDosage: number;
  referenceCalciumDosage: number;
  starchSlurryDS: number;
  calciumPrice: number;
  calciumConcentration: number;
  calciumSource: CalciumSource;
};

const CALCIUM_CONTENT_PER_SOURCE = {
  'CaCl2*2H2O': 0.272,
  CaCl2: 0.36,
};

const calcLiqCalciumDailySavings = ({
  plantCapacity,
  newCalciumDosage,
  referenceCalciumDosage,
  starchSlurryDS,
  calciumPrice,
  calciumConcentration,
  calciumSource,
}: CalcLiqCalciumDailySavingsInput): number => {
  const nonRoundedValue =
    (referenceCalciumDosage - newCalciumDosage) *
    10 ** -6 *
    ((plantCapacity * 1000 * 100) / starchSlurryDS) *
    ((calciumPrice * (100 / calciumConcentration)) / CALCIUM_CONTENT_PER_SOURCE[calciumSource]);

  return roundNumber(nonRoundedValue, 1);
};

type Chemical = Acid | Base;

export type CalcLiqChemicalDailySavingsInput = {
  chemicalName: Chemical;
  chemicalSavedPerDayInIonExchange: number;
  chemicalPrice: number;
  chemicalConcentration: number;
};

const EQ_BASE_CHEMICAL: { [key in Chemical]: number } = {
  NaOH: 25,
  Na2CO3: 18.9,
  NH4OH: 28.57,
  'Ca(OH)2': 27.0,
  HCl: 27.4,
  H2SO4: 20.4,
};

const calcLiqChemicalDailySavings = ({
  chemicalName,
  chemicalSavedPerDayInIonExchange,
  chemicalPrice,
  chemicalConcentration,
}: CalcLiqChemicalDailySavingsInput): number => {
  const nonRoundedValue =
    (chemicalSavedPerDayInIonExchange * (chemicalPrice * (100 / chemicalConcentration))) / EQ_BASE_CHEMICAL[chemicalName];
  return roundNumber(nonRoundedValue, 1);
};

export default {
  calcLiqEnzymesDailySaving,
  calcLiqCalciumDailySavings,
  calcLiqChemicalDailySavings,
};
