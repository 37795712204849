import { EnzymeModel } from './EnzymeModel';
import { EnzymeInput } from './EnzymeInput';
import DEMethods from './DEMethods';
import isEnzymeInputValid from './EnzymeInputValidator';

const LiquozymeX2H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (-212.86082 -
        time * time * 0.0002579 +
        85.16 * 2 * input.dosage * time * 0.00111787 +
        input.pH * time * 0.09214549 +
        input.pH * (85.16 * 2 * input.dosage) * 0.03715938 -
        input.pH * input.pH * 8.1477106 -
        0.4373603 * time -
        0.1660256 * (85.16 * 2 * input.dosage) +
        83.2633547 * input.pH +
        0.01765572 * input.calcium)
  );
};

const LiquozymeX4H = (input: EnzymeInput, time: number): number => {
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];

  return (
    fehlingsB +
    fehlingsA *
      (-212.86082 -
        time * time * 0.0002579 +
        85.16 * 2 * input.dosage * time * 0.00111787 +
        input.pH * time * 0.09214549 +
        input.pH * (85.16 * 2 * input.dosage) * 0.03715938 -
        input.pH * input.pH * 8.1477106 -
        0.4373603 * time -
        0.1660256 * (85.16 * 2 * input.dosage) +
        83.2633547 * input.pH +
        0.01765572 * input.calcium)
  );
};

const LiquozymeX: EnzymeModel = (input: EnzymeInput): number[] => {
  const simulation: number[] = Array(9).fill(0);

  if (!isEnzymeInputValid(input)) {
    return simulation;
  }

  const model = input.timeStep === 15 ? LiquozymeX2H : LiquozymeX4H;

  for (let timeI = 0; timeI < simulation.length; timeI += 1) {
    const time = timeI * input.timeStep;

    simulation[timeI] = model(input, time);
  }

  if (input.timeStep === 30) {
    simulation[5] = simulation[4] + (simulation[4] - simulation[3]) ** 2 / (simulation[3] - simulation[2]);
    simulation[6] = simulation[5] + (simulation[5] - simulation[4]) ** 2 / (simulation[4] - simulation[3]);
    simulation[7] = simulation[6] + (simulation[6] - simulation[5]) ** 2 / (simulation[5] - simulation[4]);
    simulation[8] = simulation[7] + (simulation[7] - simulation[6]) ** 2 / (simulation[6] - simulation[5]);
  }

  return simulation;
};

export default LiquozymeX;
