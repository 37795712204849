import { selector, DefaultValue } from 'recoil';
import calcIonExchangeSavings from 'calculation/savings/calcIonExchangeSavings';
import { EnzymeModel, CompetitorEnzymeModel } from 'calculation/enzymeModels/EnzymeModel';
import getModels from 'calculation/enzymeModels/models';
import calcReductionInInonEchange from 'calculation/ionExchange/calcReductionInIonExchange';
import calcCalcium from 'calculation/utils/calcCalcium';
import calcChemicalCost from 'calculation/ionExchange/calcChemicalCost';
import calcWaterCost, { EvaporatorType } from 'calculation/ionExchange/calcWaterCost';
import calcResinCost from 'calculation/ionExchange/calcResinCost';
import calcLiquefactionSavings from 'calculation/savings/calcLiquefactionSavings';
import { Acid, Base, CalciumSource } from 'calculation/types';
import {
  Effects,
  ThermalVaporRecompressionInput,
  MechanicalVaporRecompressionInput,
} from 'calculation/ionExchange/sweetWaterEvaporation';
import roundNumber from 'utils/roundNumber';
import undefinedInputChecker from 'utils/undefinedInputChecker';
import { DEMethodType, EnzymeInput, CompetitorInput } from 'calculation/enzymeModels/EnzymeInput';
import { Region, ScenarioData, TimeStep } from 'scenarios/ScenarioType';
import getDefaultPrices from 'scenarios/defaultPrices';
import currencies from 'scenarios/currencies';
import isEqualWith from 'lodash/isEqualWith';
import getEnzymeRange, { ENZYME_RANGES_4H, ENZYME_RANGES_2H } from 'scenarios/enzymeData';
import getAtomState, { activeScenarioState, remoteScenarioState } from './atomState';

const { calcLiqEnzymesDailySaving, calcLiqCalciumDailySavings, calcLiqChemicalDailySavings } = calcLiquefactionSavings;

const { calcCationChemical, calcAnionChemical } = calcChemicalCost;
const { calcReductionOfCalcium, calcReductionOfChemical } = calcReductionInInonEchange;
const { calcWasteWater, calcWater, calcSweetWaterEvaporation } = calcWaterCost;

const {
  calcIonExchChemicalSavings,
  calcIonExchResinSavings,
  calcIonExchWasteWaterSavings,
  calcIonExchWaterSavings,
  calcIonExchSweetWaterSavings,
} = calcIonExchangeSavings;

export const areEnzymePricesGivenState = selector<boolean>({
  key: 'areEnzymePricesGiven',
  get: ({ get }) => {
    const refEnzymePrice = get(getAtomState<number>('refEnzymePrice'));
    const newEnymePrice = get(getAtomState<number>('newEnzymePrice'));
    return !!refEnzymePrice && !!newEnymePrice;
  },
});

export const activeScenarioRegion = selector<Region | undefined>({
  key: 'activeScenarioRegion',
  get: ({ get }) => {
    const currentScenario = get(activeScenarioState);

    return currentScenario ? currentScenario.region : undefined;
  },
  set: ({ get, set }, newRegion) => {
    const currentScenario = get(activeScenarioState);

    if (!(newRegion instanceof DefaultValue) && newRegion) {
      const parsedRegion = newRegion as Region;
      const defaultPrices = getDefaultPrices(parsedRegion);

      const currentData = currentScenario.data;

      const scenarioData: ScenarioData = {
        ...currentData,
        baseChemicalPrice: currentData.baseChemicalName ? defaultPrices[currentData.baseChemicalName] : undefined,
        acidChemicalPrice: currentData.acidChemicalName ? defaultPrices[currentData.acidChemicalName] : undefined,
        calciumPrice: currentData.calciumSourceName ? defaultPrices[currentData.calciumSourceName] : undefined,
        electricityPrice: defaultPrices.electricity,
        steamPrice: defaultPrices.steam,
        catResinPrice: defaultPrices.catResin,
        anResinPrice: defaultPrices.anResin,
        catChemPrice: currentData.catRegChem ? defaultPrices[currentData.catRegChem] : undefined,
        anChemPrice: currentData.anRegChem ? defaultPrices[currentData.anRegChem] : undefined,
        waterPrice: defaultPrices.processWater,
        wasteWaterCost: defaultPrices.wasteWater,
      };

      set(activeScenarioState, { ...currentScenario, region: parsedRegion, data: scenarioData });
    }
  },
});

export const availableEnyzmes = selector({
  key: 'availableEnyzmes',
  get: ({ get }) => {
    const activeRegion = get(activeScenarioRegion);
    if (!activeRegion) {
      return {};
    }
    const models = getModels(activeRegion);
    return models;
  },
});

export const currency = selector({
  key: 'currency',
  get: ({ get }) => {
    const activeRegion = get(activeScenarioRegion);
    if (!activeRegion) {
      return '';
    }

    return currencies[activeRegion];
  },
});

export const reductionOfCalcium = selector<number>({
  key: 'reductionOfCalcium',
  get: ({ get }) => {
    const referenceCalciumDosage = getAtomState<number>('refEnzymeCalcium');
    const newCalciumDosage = getAtomState<number>('newEnzymeCalcium');
    const plantCapacity = getAtomState<number>('plantCapacity');
    const DSSlurryLiq = getAtomState<number>('DSSlurryLiq');

    const checkedFunction = undefinedInputChecker<number>(calcReductionOfCalcium, 0);

    return checkedFunction({
      referenceCalciumDosage: get(referenceCalciumDosage),
      newCalciumDosage: get(newCalciumDosage),
      liquefactionCapacity: get(plantCapacity),
      starchSlurryDS: get(DSSlurryLiq),
    });
  },
});

export const reductionOfChemicals = selector<number>({
  key: 'reductionOfChemicals',
  get: ({ get }) => {
    const plantCapacity = getAtomState<number>('plantCapacity');
    const DSSlurryLiq = getAtomState<number>('DSSlurryLiq');
    const newpH = getAtomState<number>('newEnzymepH');
    const referencepH = getAtomState<number>('refEnzymepH');
    const starchSlurrySampleDS = getAtomState<number>('DSSlurrySample');

    const titrationCoefficientA = getAtomState<number>('titrationCoefficientA');
    const titrationCoefficientB = getAtomState<number>('titrationCoefficientB');
    const titrationCoefficientC = getAtomState<number>('titrationCoefficientC');
    const titrationCoefficientD = getAtomState<number>('titrationCoefficientD');
    const titrationCoefficientE = getAtomState<number>('titrationCoefficientE');

    const checkedFunction = undefinedInputChecker<number>(calcReductionOfChemical, 0);

    return checkedFunction({
      liquefactionCapacity: get(plantCapacity),
      starchSlurryDS: get(DSSlurryLiq),
      starchSlurrySampleDS: get(starchSlurrySampleDS),
      slurryTitrationRegressionCoefficients: {
        a: get(titrationCoefficientA),
        b: get(titrationCoefficientB),
        c: get(titrationCoefficientC),
        d: get(titrationCoefficientD),
        e: get(titrationCoefficientE),
      },
      newpH: get(newpH),
      referencepH: get(referencepH),
    });
  },
});

export const totalIonExchangeReduction = selector<number>({
  key: 'totalIonExchangeReduction',
  get: ({ get }) => {
    return [get(reductionOfChemicals), get(reductionOfCalcium)].reduce((acc, val) => acc + val, 0);
  },
});

export const phAdjustNewEnzyme = selector<number>({
  key: 'phAdjustNewEnzyme',
  get: ({ get }) => {
    const DSSlurryLiq = getAtomState<number>('DSSlurryLiq');
    const newpH = getAtomState<number>('newEnzymepH');
    const DSSlurrySample = getAtomState<number>('DSSlurrySample');

    const baseChemicalName = getAtomState<Base>('baseChemicalName');
    const slurryLiquidpH = getAtomState<number>('slurryLiquidpH');

    const titrationCoefficientA = getAtomState<number>('titrationCoefficientA');
    const titrationCoefficientB = getAtomState<number>('titrationCoefficientB');
    const titrationCoefficientC = getAtomState<number>('titrationCoefficientC');
    const titrationCoefficientD = getAtomState<number>('titrationCoefficientD');
    const titrationCoefficientE = getAtomState<number>('titrationCoefficientE');

    const input = {
      enzymeLiquidpH: get(newpH),
      DSSlurryLiq: get(DSSlurryLiq),
      baseLiquid: get(baseChemicalName),
      slurryLiquidpH: get(slurryLiquidpH),
      DSSlurrySample: get(DSSlurrySample),
      slurryTitrationRegressionCoefficients: {
        a: get(titrationCoefficientA),
        b: get(titrationCoefficientB),
        c: get(titrationCoefficientC),
        d: get(titrationCoefficientD),
        e: get(titrationCoefficientE),
      },
    };

    const checkedFunction = undefinedInputChecker<number>(calcCalcium, 0);
    return checkedFunction(input);
  },
});
export const phAdjustRefEnzyme = selector<number>({
  key: 'phAdjustRefEnzyme',
  get: ({ get }) => {
    const DSSlurryLiq = getAtomState<number>('DSSlurryLiq');
    const refPh = getAtomState<number>('refEnzymepH');
    const DSSlurrySample = getAtomState<number>('DSSlurrySample');

    const baseChemicalName = getAtomState<Base>('baseChemicalName');
    const slurryLiquidpH = getAtomState<number>('slurryLiquidpH');

    const titrationCoefficientA = getAtomState<number>('titrationCoefficientA');
    const titrationCoefficientB = getAtomState<number>('titrationCoefficientB');
    const titrationCoefficientC = getAtomState<number>('titrationCoefficientC');
    const titrationCoefficientD = getAtomState<number>('titrationCoefficientD');
    const titrationCoefficientE = getAtomState<number>('titrationCoefficientE');

    const checkedFunction = undefinedInputChecker<number>(calcCalcium, 0);

    return checkedFunction({
      enzymeLiquidpH: get(refPh),
      DSSlurryLiq: get(DSSlurryLiq),
      baseLiquid: get(baseChemicalName),
      slurryLiquidpH: get(slurryLiquidpH),
      DSSlurrySample: get(DSSlurrySample),
      slurryTitrationRegressionCoefficients: {
        a: get(titrationCoefficientA),
        b: get(titrationCoefficientB),
        c: get(titrationCoefficientC),
        d: get(titrationCoefficientD),
        e: get(titrationCoefficientE),
      },
    });
  },
});

export const enzymeRanges = selector({
  key: 'enzymeRanges',
  get: ({ get }) => {
    const timeStep = get(getAtomState('timeStep'));
    return timeStep === 30 ? ENZYME_RANGES_4H : ENZYME_RANGES_2H;
  },
});

export const newEnzymeModelOutput = selector<number[]>({
  key: 'newEnzymeModelOutput',
  get: ({ get }) => {
    const nameState = getAtomState<number>('newEnzymeName');
    const dosageState = getAtomState<number>('newEnzymeDosage');
    const pHState = getAtomState<number>('newEnzymepH');
    const calciumState = getAtomState<number>('newEnzymeCalcium');
    const timeStepState = getAtomState<TimeStep>('timeStep');
    const DEMethod = getAtomState<DEMethodType>('DEMethod');
    const modelName = get(nameState);

    if (!modelName) {
      return [];
    }

    const models = get(availableEnyzmes);
    const model = models[modelName];

    const totalCalcium = Number(get(calciumState) || 0) + Number(get(phAdjustNewEnzyme));
    const ENZYME_RANGES = get(enzymeRanges);
    const ranges = ENZYME_RANGES[modelName];
    const maxCalcium = ranges && ranges.calcium.max;

    const modelInput: Partial<EnzymeInput> = {
      dosage: get(dosageState),
      pH: get(pHState),
      calcium: maxCalcium && totalCalcium >= maxCalcium ? maxCalcium : totalCalcium,
      timeStep: get(timeStepState),
      DEMethod: get(DEMethod),
    };

    const checkedFunction = undefinedInputChecker<number[]>(model as EnzymeModel, []);

    return checkedFunction(modelInput);
  },
});
export const refEnzymeModelOutput = selector<number[]>({
  key: 'refEnzymeModelOutput',
  get: ({ get }) => {
    const nameState = getAtomState<string>('refEnzymeName');
    const dosageState = getAtomState<number>('refEnzymeDosage');
    const pHState = getAtomState<number>('refEnzymepH');
    const calciumState = getAtomState<number>('refEnzymeCalcium');
    const timeStepState = getAtomState<TimeStep>('timeStep');
    const DEMethod = getAtomState<DEMethodType>('DEMethod');

    const competitorDEMethod = getAtomState<DEMethodType>('competitorDEMethod');
    const liquefactionDE = getAtomState<number>('liquefactionDE');
    const liquefactionTime = getAtomState<number>('liquefactionTime');

    const modelName = get(nameState);

    if (!modelName) {
      return [];
    }

    const models = get(availableEnyzmes);
    const model = modelName && models[modelName];
    const totalCalcium = Number(get(calciumState) || 0) + Number(get(phAdjustNewEnzyme));
    const ENZYME_RANGES = get(enzymeRanges);
    const ranges = ENZYME_RANGES[modelName];
    const maxCalcium = ranges && ranges.calcium.max;

    if (modelName === 'Competitor') {
      const modelInput: Partial<CompetitorInput> = {
        competitorDEMethod: get(competitorDEMethod),
        DEMethod: get(DEMethod),
        liquefactionDE: get(liquefactionDE),
        liquefactionTime: get(liquefactionTime),
        timeStep: get(timeStepState),
      };
      const checkedFunction = undefinedInputChecker<number[]>(model as CompetitorEnzymeModel, []);
      return checkedFunction(modelInput);
    }
    const modelInput: Partial<EnzymeInput> = {
      dosage: get(dosageState),
      pH: get(pHState),
      calcium: maxCalcium && totalCalcium >= maxCalcium ? maxCalcium : totalCalcium,
      timeStep: get(timeStepState),
      DEMethod: get(DEMethod),
    };
    const checkedFunction = undefinedInputChecker<number[]>(model as EnzymeModel, []);
    return checkedFunction(modelInput);
  },
});

export const ionExchangeCatChem = selector<number>({
  key: 'ionExchangeCatChem',
  get: ({ get }) => {
    const acidName = getAtomState<Acid>('catRegChem');
    const baseName = getAtomState<Base>('anRegChem');
    const acidChemicalConcentration = getAtomState<number>('catChemConcentration');
    const baseChemicalConcentration = getAtomState<number>('anChemConcentration');
    const acidChemicalConsumption = getAtomState<number>('catChemConsumption');
    const baseChemicalConsumption = getAtomState<number>('anChemConsumption');
    const acidChemicalPrice = getAtomState<number>('catChemPrice');
    const baseChemicalPrice = getAtomState<number>('anChemPrice');

    const crossRegeneration = getAtomState<number>('catCrossRegen');
    const resinCapacity = getAtomState<number>('catResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcCationChemical, 0);

    return checkedFunction({
      acidName: get(acidName),
      baseName: get(baseName),
      acidChemicalConcentration: get(acidChemicalConcentration),
      baseChemicalConcentration: get(baseChemicalConcentration),
      acidChemicalConsumption: get(acidChemicalConsumption),
      baseChemicalConsumption: get(baseChemicalConsumption),
      acidChemicalPrice: get(acidChemicalPrice),
      baseChemicalPrice: get(baseChemicalPrice),
      crossRegeneration: get(crossRegeneration),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeAnChem = selector<number>({
  key: 'ionExchangeAnChem',
  get: ({ get }) => {
    const acidName = getAtomState<Acid>('catRegChem');
    const baseName = getAtomState<Base>('anRegChem');
    const acidChemicalConcentration = getAtomState<number>('catChemConcentration');
    const baseChemicalConcentration = getAtomState<number>('anChemConcentration');
    const acidChemicalConsumption = getAtomState<number>('catChemConsumption');
    const baseChemicalConsumption = getAtomState<number>('anChemConsumption');
    const acidChemicalPrice = getAtomState<number>('catChemPrice');
    const baseChemicalPrice = getAtomState<number>('anChemPrice');

    // Note: These are different from ionExchangeCatChem
    // TODO: Look at optimizing re-usability
    const crossRegeneration = getAtomState<number>('anCrossRegen');
    const resinCapacity = getAtomState<number>('anResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcAnionChemical, 0);

    return checkedFunction({
      acidName: get(acidName),
      baseName: get(baseName),
      acidChemicalConcentration: get(acidChemicalConcentration),
      baseChemicalConcentration: get(baseChemicalConcentration),
      acidChemicalConsumption: get(acidChemicalConsumption),
      baseChemicalConsumption: get(baseChemicalConsumption),
      acidChemicalPrice: get(acidChemicalPrice),
      baseChemicalPrice: get(baseChemicalPrice),
      crossRegeneration: get(crossRegeneration),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeCatWasteWater = selector<number>({
  key: 'ionExchangeCatWasteWater',
  get: ({ get }) => {
    const wasteWaterCost = getAtomState<number>('wasteWaterCost');
    const wasteWaterUsage = getAtomState<number>('catWasteWater');
    const resinCapacity = getAtomState<number>('catResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcWasteWater, 0);

    return checkedFunction({
      costWasteWater: get(wasteWaterCost),
      wasteWaterUsage: get(wasteWaterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeAnWasteWater = selector<number>({
  key: 'ionExchangeAnWasteWater',
  get: ({ get }) => {
    const wasteWaterCost = getAtomState<number>('wasteWaterCost');
    const wasteWaterUsage = getAtomState<number>('anWasteWater');
    const resinCapacity = getAtomState<number>('anResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcWasteWater, 0);

    return checkedFunction({
      costWasteWater: get(wasteWaterCost),
      wasteWaterUsage: get(wasteWaterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeCatWater = selector<number>({
  key: 'ionExchangeCatWater',
  get: ({ get }) => {
    const waterPrice = getAtomState<number>('waterPrice');
    const waterUsage = getAtomState<number>('catWater');
    const resinCapacity = getAtomState<number>('catResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcWater, 0);

    return checkedFunction({
      priceWater: get(waterPrice),
      waterUsage: get(waterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeAnWater = selector<number>({
  key: 'ionExchangeAnWater',
  get: ({ get }) => {
    const waterPrice = getAtomState<number>('waterPrice');
    const waterUsage = getAtomState<number>('anWater');
    const resinCapacity = getAtomState<number>('anResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcWater, 0);

    return checkedFunction({
      priceWater: get(waterPrice),
      waterUsage: get(waterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeCatResin = selector<number>({
  key: 'ionExchangeCatResin',
  get: ({ get }) => {
    const resinPrice = getAtomState<number>('catResinPrice');
    const resinLifetime = getAtomState<number>('catResinLife');
    const resinCapacity = getAtomState<number>('catResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcResinCost, 0);

    return checkedFunction({
      resinPrice: get(resinPrice),
      resinLifetime: get(resinLifetime),
      resinExchangeCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeAnResin = selector<number>({
  key: 'ionExchangeAnResin',
  get: ({ get }) => {
    const resinPrice = getAtomState<number>('anResinPrice');
    const resinLifetime = getAtomState<number>('anResinLife');
    const resinCapacity = getAtomState<number>('anResinCap');

    const checkedFunction = undefinedInputChecker<number>(calcResinCost, 0);

    return checkedFunction({
      resinPrice: get(resinPrice),
      resinLifetime: get(resinLifetime),
      resinExchangeCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeCatSweetWater = selector<number>({
  key: 'ionExchangeCatSweetWater',
  get: ({ get }) => {
    const evaporatorType = getAtomState<EvaporatorType>('evaporatorType');
    const electricityPrice = getAtomState<number>('electricityPrice');
    const electricityConsumption = getAtomState<number>('electricityConsumption');
    const effects = getAtomState<Effects>('effects');
    const steamPrice = getAtomState<number>('steamPrice');

    const resinCapacity = getAtomState<number>('catResinCap');
    const sweetWaterUsage = getAtomState<number>('catSWDilution');

    const getSweetWaterEvapInput = (): ThermalVaporRecompressionInput | MechanicalVaporRecompressionInput | undefined => {
      if (get(evaporatorType) === 'MVR') {
        return {
          electricityPrice: get(electricityPrice) || 0,
          electricityConsumption: get(electricityConsumption) || 0,
        };
      }
      if (get(evaporatorType) === 'TVR') {
        return {
          effects: get(effects),
          steamPrice: get(steamPrice) || 0,
        };
      }
      return undefined;
    };

    const checkedFunction = undefinedInputChecker<number>(calcSweetWaterEvaporation, 0);

    return checkedFunction({
      evaporatorType: get(evaporatorType),
      evaporatorInput: getSweetWaterEvapInput(),
      sweetWaterUsage: get(sweetWaterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeAnSweetWater = selector<number>({
  key: 'ionExchangeAnSweetWater',
  get: ({ get }) => {
    const evaporatorType = getAtomState<EvaporatorType>('evaporatorType');
    const electricityPrice = getAtomState<number>('electricityPrice');
    const electricityConsumption = getAtomState<number>('electricityConsumption');
    const effects = getAtomState<Effects>('effects');
    const steamPrice = getAtomState<number>('steamPrice');

    const resinCapacity = getAtomState<number>('anResinCap');
    const sweetWaterUsage = getAtomState<number>('anSWDilution');

    const getSweetWaterEvapInput = (): ThermalVaporRecompressionInput | MechanicalVaporRecompressionInput | undefined => {
      if (get(evaporatorType) === 'MVR') {
        return {
          electricityPrice: get(electricityPrice) || 0,
          electricityConsumption: get(electricityConsumption) || 0,
        };
      }
      if (get(evaporatorType) === 'TVR') {
        return {
          effects: get(effects),
          steamPrice: get(steamPrice) || 0,
        };
      }
      return undefined;
    };

    const checkedFunction = undefinedInputChecker<number>(calcSweetWaterEvaporation, 0);

    return checkedFunction({
      evaporatorType: get(evaporatorType),
      evaporatorInput: getSweetWaterEvapInput(),
      sweetWaterUsage: get(sweetWaterUsage),
      resinCapacity: get(resinCapacity),
    });
  },
});

export const ionExchangeCatTotal = selector<number>({
  key: 'ionExchangeCatTotal',
  get: ({ get }) => {
    const sum = [
      get(ionExchangeCatChem),
      get(ionExchangeCatWasteWater),
      get(ionExchangeCatWater),
      get(ionExchangeCatResin),
      get(ionExchangeCatSweetWater),
    ].reduce((acc, val) => {
      return acc + val;
    }, 0);

    return roundNumber(sum, 4);
  },
});

export const ionExchangeAnTotal = selector<number>({
  key: 'ionExchangeAnTotal',
  get: ({ get }) => {
    const sum = [
      get(ionExchangeAnChem),
      get(ionExchangeAnWasteWater),
      get(ionExchangeAnWater),
      get(ionExchangeAnResin),
      get(ionExchangeAnSweetWater),
    ].reduce((acc, val) => {
      return acc + val;
    }, 0);

    return roundNumber(sum, 4);
  },
});

export const ixChemicalSavings = selector<number>({
  key: 'ixChemicalSavings',
  get: ({ get }) => {
    return calcIonExchChemicalSavings({
      numberOfSavedEqToCation: get(totalIonExchangeReduction),
      numberOfSavedEqToAnion: get(totalIonExchangeReduction),
      chemicalCationIonExchCost: get(ionExchangeCatChem),
      chemicalAnionIonExchCost: get(ionExchangeAnChem),
    });
  },
});

export const liqEnzymeSavings = selector<number>({
  key: 'liqEnzymeSavings',
  get: ({ get }) => {
    const plantCapacity = getAtomState<number>('plantCapacity');
    const dosageReferenceEnzyme = getAtomState<number>('refEnzymeDosage');
    const priceReferenceEnzyme = getAtomState<number>('refEnzymePrice');
    const dosageNewEnzyme = getAtomState<number>('newEnzymeDosage');
    const priceNewEnzyme = getAtomState<number>('newEnzymePrice');

    const checkedFunction = undefinedInputChecker<number>(calcLiqEnzymesDailySaving, 0);

    const arePricesGiven = get(areEnzymePricesGivenState);

    const input = {
      plantCapacity: get(plantCapacity),
      dosageReferenceEnzyme: get(dosageReferenceEnzyme),
      priceReferenceEnzyme: get(priceReferenceEnzyme),
      dosageNewEnzyme: get(dosageNewEnzyme),
      priceNewEnzyme: get(priceNewEnzyme),
    };

    return arePricesGiven ? checkedFunction(input) : 0;
  },
});
export const liqCalciumSavings = selector<number>({
  key: 'liqCalciumSavings',
  get: ({ get }) => {
    const plantCapacity = getAtomState<number>('plantCapacity');
    const newCalciumDosage = getAtomState<number>('newEnzymeCalcium');
    const referenceCalciumDosage = getAtomState<number>('refEnzymeCalcium');
    const starchSlurryDS = getAtomState<number>('DSSlurryLiq');
    const calciumPrice = getAtomState<number>('calciumPrice');
    const calciumConcentration = getAtomState<number>('calciumConcentration');
    const calciumSource = getAtomState<CalciumSource>('calciumSourceName');

    const checkedFunction = undefinedInputChecker<number>(calcLiqCalciumDailySavings, 0);

    return checkedFunction({
      plantCapacity: get(plantCapacity),
      newCalciumDosage: get(newCalciumDosage),
      referenceCalciumDosage: get(referenceCalciumDosage),
      starchSlurryDS: get(starchSlurryDS),
      calciumPrice: get(calciumPrice),
      calciumConcentration: get(calciumConcentration),
      calciumSource: get(calciumSource),
    });
  },
});
export const liqBaseSavings = selector<number>({
  key: 'liqBaseSavings',
  get: ({ get }) => {
    const baseChemicalName = getAtomState<Base>('baseChemicalName');
    const baseChemicalPrice = getAtomState<number>('baseChemicalPrice');
    const baseChemicalConcentration = getAtomState<number>('baseChemicalConcentration');

    const checkedFunction = undefinedInputChecker<number>(calcLiqChemicalDailySavings, 0);

    const result = checkedFunction({
      chemicalName: get(baseChemicalName),
      chemicalSavedPerDayInIonExchange: get(reductionOfChemicals),
      chemicalPrice: get(baseChemicalPrice),
      chemicalConcentration: get(baseChemicalConcentration),
    });
    return result;
  },
});
export const liqAcidSavings = selector<number>({
  key: 'liqAcidSavings',
  get: ({ get }) => {
    const acidChemicalName = getAtomState<Base>('acidChemicalName');
    const acidChemicalPrice = getAtomState<number>('acidChemicalPrice');
    const acidChemicalConcentration = getAtomState<number>('acidChemicalConcentration');

    const checkedFunction = undefinedInputChecker<number>(calcLiqChemicalDailySavings, 0);

    return checkedFunction({
      chemicalName: get(acidChemicalName),
      chemicalSavedPerDayInIonExchange: get(reductionOfChemicals),
      chemicalPrice: get(acidChemicalPrice),
      chemicalConcentration: get(acidChemicalConcentration),
    });
  },
});
export const ionExchangeResinSavings = selector<number>({
  key: 'ionExchangeResinSavings',
  get: ({ get }) => {
    return calcIonExchResinSavings({
      numberOfSavedEqToCation: get<number>(totalIonExchangeReduction),
      numberOfSavedEqToAnion: get(totalIonExchangeReduction),
      resinCationIonExchCost: get(ionExchangeCatResin),
      resinAnionIonExchCost: get(ionExchangeAnResin),
    });
  },
});
export const ionExchWasteWaterSavings = selector<number>({
  key: 'ionExchWasteWaterSavings',
  get: ({ get }) => {
    return calcIonExchWasteWaterSavings({
      numberOfSavedEqToCation: get(totalIonExchangeReduction),
      numberOfSavedEqToAnion: get(totalIonExchangeReduction),
      wasteWaterCationIonExchCost: get(ionExchangeCatWasteWater),
      wasteWaterAnionIonExchCost: get(ionExchangeAnWasteWater),
    });
  },
});
export const ionExchWaterSavings = selector<number>({
  key: 'ionExchWaterSavings',
  get: ({ get }) => {
    return calcIonExchWaterSavings({
      numberOfSavedEqToCation: get(totalIonExchangeReduction),
      numberOfSavedEqToAnion: get(totalIonExchangeReduction),
      waterCationIonExchCost: get(ionExchangeCatWater),
      waterAnionIonExchCost: get(ionExchangeAnWater),
    });
  },
});
export const ionExchSweetWaterSavings = selector<number>({
  key: 'ionExchSweetWaterSavings',
  get: ({ get }) => {
    return calcIonExchSweetWaterSavings({
      numberOfSavedEqToCation: get(totalIonExchangeReduction),
      numberOfSavedEqToAnion: get(totalIonExchangeReduction),
      sweetWaterCationIonExchCost: get(ionExchangeCatSweetWater),
      sweetWaterAnionIonExchCost: get(ionExchangeAnSweetWater),
    });
  },
});

export const dailyTotalsWithoutEnzymesState = selector<number>({
  key: 'dailyTotalsWithoutEnzymes',
  get: ({ get }) => {
    return (
      get(liqCalciumSavings) +
      get(liqBaseSavings) +
      get(liqAcidSavings) +
      get(ionExchangeResinSavings) +
      get(ixChemicalSavings) +
      get(ionExchWasteWaterSavings) +
      get(ionExchWaterSavings) +
      get(ionExchSweetWaterSavings)
    );
  },
});

export const dailyTotalsWithEnzymesState = selector<number>({
  key: 'dailyTotalsWithEnzymes',
  get: ({ get }) => {
    return (
      get(liqEnzymeSavings) +
      get(liqCalciumSavings) +
      get(liqBaseSavings) +
      get(liqAcidSavings) +
      get(ionExchangeResinSavings) +
      get(ixChemicalSavings) +
      get(ionExchWasteWaterSavings) +
      get(ionExchWaterSavings) +
      get(ionExchSweetWaterSavings)
    );
  },
});

export const isActiveScenarioEdited = selector<boolean>({
  key: 'isActiveScenarioEdited',
  get: ({ get }) => {
    const activeScenario = get(activeScenarioState);

    if (activeScenario.id === undefined) {
      return true;
    }

    const savedVersion = get(remoteScenarioState);

    if (savedVersion === undefined) {
      return true;
    }

    const customizer = (valueA: any, valueB: any): boolean | undefined => {
      const aEmptyUndefined = valueA === undefined || valueA === '';
      const bEmptyUndefined = valueB === undefined || valueB === '';

      return aEmptyUndefined && bEmptyUndefined ? true : undefined;
    };

    // Check if the saved version is different from the active one
    return !isEqualWith(savedVersion, activeScenario, customizer);
  },
});

const checkIfInRange = (min: number, max: number, value: number): boolean => max >= value && min <= value;

export const isEnzymesOutOfRangeState = selector<boolean>({
  key: 'isEnzymesOutOfRange',
  get: ({ get }) => {
    const timeStep = get(getAtomState<TimeStep>('timeStep'));

    const newEnzymeName = get(getAtomState<string>('newEnzymeName'));
    const refEnzymeName = get(getAtomState<string>('refEnzymeName'));

    const newEnzymeDosage = get(getAtomState<number>('newEnzymeDosage'));
    const refEnzymeDosage = get(getAtomState<number>('refEnzymeDosage'));

    const newEnzymepH = get(getAtomState<number>('newEnzymepH'));
    const refEnzymepH = get(getAtomState<number>('refEnzymepH'));

    const newEnzymeCalcium = get(getAtomState<number>('newEnzymeCalcium'));
    const refEnzymeCalcium = get(getAtomState<number>('refEnzymeCalcium'));

    if (
      !timeStep ||
      !newEnzymeName ||
      !refEnzymeName ||
      !newEnzymeDosage ||
      !refEnzymeDosage ||
      !newEnzymepH ||
      !refEnzymepH ||
      !newEnzymeCalcium ||
      !refEnzymeCalcium
    ) {
      return false;
    }

    const newEnzDosageRange = getEnzymeRange(newEnzymeName, 'newEnzymeDosage', timeStep);
    const refEnzDosageRange = getEnzymeRange(refEnzymeName, 'refEnzymeDosage', timeStep);
    const newEnzpHRange = getEnzymeRange(newEnzymeName, 'newEnzymepH', timeStep);
    const refEnzpHRange = getEnzymeRange(refEnzymeName, 'refEnzymepH', timeStep);
    const newEnzCalciumRange = getEnzymeRange(newEnzymeName, 'newEnzymeCalcium', timeStep);
    const refEnzCalciumRange = getEnzymeRange(refEnzymeName, 'refEnzymeCalcium', timeStep);

    if (
      !newEnzDosageRange ||
      !refEnzDosageRange ||
      !newEnzpHRange ||
      !refEnzpHRange ||
      !newEnzCalciumRange ||
      !refEnzCalciumRange
    ) {
      return false;
    }

    const isDosageInRange =
      checkIfInRange(newEnzDosageRange.min, newEnzDosageRange.max, newEnzymeDosage) &&
      checkIfInRange(refEnzDosageRange.min, refEnzDosageRange.max, refEnzymeDosage);

    const ispHInRange =
      checkIfInRange(newEnzpHRange.min, newEnzpHRange.max, newEnzymepH) &&
      checkIfInRange(refEnzpHRange.min, refEnzpHRange.max, refEnzymepH);

    const isCalciumInRange =
      checkIfInRange(newEnzCalciumRange.min, newEnzCalciumRange.max, newEnzymeCalcium) &&
      checkIfInRange(refEnzCalciumRange.min, refEnzCalciumRange.max, refEnzymeCalcium);

    return !isDosageInRange || !ispHInRange || !isCalciumInRange;
  },
});
