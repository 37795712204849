import { getNextRoute } from 'processNavigation';
import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Redirecter = ({ redirectTo, children }: { redirectTo?: string; children: ReactNode }): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  const forwardUrl = useMemo(() => getNextRoute(location.pathname), [location.pathname]);

  useEffect(() => {
    if (redirectTo) {
      const path = location.pathname;
      if (path !== redirectTo) {
        if (redirectTo === '/' && forwardUrl) {
          history.replace(forwardUrl);
        }
        history.replace(redirectTo);
      }
    }
  }, [redirectTo]);

  return <>{children}</>;
};

export default Redirecter;
