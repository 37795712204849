import React, { ReactElement, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getNextRoute } from 'processNavigation';

function Home(): ReactElement {
  const location = useLocation();
  const history = useHistory();

  const forwardUrl = useMemo(() => getNextRoute(location.pathname), [location.pathname]);

  useEffect(() => {
    if (forwardUrl) history.replace(forwardUrl);
  }, [location.pathname, history]);

  return <></>;
}

export default Home;
