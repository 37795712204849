import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { Toolbar, makeStyles, Theme, Box } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { PROCESS_STEPS, getRouteIndex, getCurrentRoute } from 'processNavigation';
import { activeScenarioState, scenarioErrorState } from 'state/atomState';
import getScenarioValidationErrors from 'scenarios/scenarioValidation';
import { ScenarioErrors } from 'scenarios/ScenarioType';
import DownloadLink from 'pdf/DownloadLink';
import { Share, FolderAdd } from '@novozymes/components';
import ShareScenarioDialog from 'components/dialogs/ShareScenarioDialog';
import ButtonWithIcon from 'components/ButtonWithIcon';
import { isShareEnabled } from 'utils/processUtils';
import SaveDialog from 'components/dialogs/SaveDialog';

const useStyles = makeStyles((theme: Theme) => ({
  processHeaderContainer: {
    paddingLeft: theme.spacing(6),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inlineMenu: {
    display: 'inline-flex',
  },
}));

const useLinkStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    marginRight: theme.spacing(1),
    '&:first-child div': {
      paddingLeft: theme.spacing(1),
    },
  },
  processStepLink: ({ active }: { active: boolean }): CreateCSSProperties<{ active: boolean }> => ({
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.common.white,
    fontWeight: active ? 'bold' : 'normal',
    textDecoration: 'none',
    padding: theme.spacing(2),
    '&:hover': {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  }),
  underline: {
    height: '2px',
    backgroundColor: fade(theme.palette.common.white, 0.8),
  },
  underlinePlaceholder: {
    height: '2px',
  },
}));

const MenuLink = ({ title, url }: { title: string; url: string }): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const active = url === location.pathname;
  const setErrorState = useSetRecoilState(scenarioErrorState);

  const menuRoute = useMemo(() => getCurrentRoute(url), [location.pathname]);
  const currentRouteIndex = useMemo(() => getRouteIndex(location.pathname), [location.pathname]);
  const menuRouteIndex = useMemo(() => getRouteIndex(url), [url]);

  const classes = useLinkStyles({ active });

  const validateCurrentScenario = useRecoilCallback(
    ({ snapshot }) =>
      (): ScenarioErrors | undefined => {
        const snapshotLoadable = snapshot.getLoadable(activeScenarioState);
        const routeIndex = getRouteIndex(location.pathname);
        if (snapshotLoadable.state === 'hasValue' && (routeIndex === 0 || routeIndex === 1)) {
          const currentScenario = snapshotLoadable.contents;
          const errors = getScenarioValidationErrors(routeIndex)(currentScenario.data);

          setErrorState(errors);
          return errors;
        }
      },
    [location.pathname]
  );

  const handleLinkClick = useCallback(() => {
    const goingBack = menuRouteIndex < currentRouteIndex;
    const errors = goingBack ? {} : validateCurrentScenario();
    if (!(errors && Object.keys(errors).length)) {
      if (menuRoute) history.push(menuRoute);
    }
  }, [history, location, url]);

  return (
    <Box className={classes.linkWrapper}>
      <Box key={title} onClick={handleLinkClick} className={classes.processStepLink}>
        {title}
        {active ? <Box className={classes.underline} /> : <Box className={classes.underlinePlaceholder} />}
      </Box>
    </Box>
  );
};

const ProcessHeader = (): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const isStartRoute = useMemo(() => location.pathname === '/', [location.pathname]);

  const activeScenario = useRecoilValue(activeScenarioState);

  const [showShare, setShowShare] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const handleShareClick = useCallback(() => {
    setShowShare(true);
  }, []);

  const handleShareClose = useCallback(() => {
    setShowShare(false);
  }, []);

  const handleSaveClick = useCallback(() => {
    setSaveModalOpen(true);
  }, []);

  const handleSaveClose = useCallback(() => {
    setSaveModalOpen(false);
  }, []);

  return (
    <>
      <SaveDialog show={saveModalOpen} onClose={handleSaveClose} scenario={activeScenario} />
      <ShareScenarioDialog show={showShare} scenario={activeScenario} onClose={handleShareClose} />
      <Toolbar component="nav" variant="dense" className={classes.processHeaderContainer}>
        <Box className={classes.inlineMenu}>
          {PROCESS_STEPS.map((step) => (
            <MenuLink key={step.title} {...step} />
          ))}
        </Box>
        <Box className={classes.inlineMenu}>
          {activeScenario.id && <DownloadLink linkText="Export PDF" />}
          <Box mx={1} />
          {!isStartRoute && <ButtonWithIcon id="btn-save" title="Save" icon={<FolderAdd />} onClick={handleSaveClick} />}
          <Box mx={1} />
          {!isStartRoute && (
            <ButtonWithIcon
              id="btn-share"
              disabled={!isShareEnabled()}
              title="Share"
              icon={<Share />}
              onClick={handleShareClick}
            />
          )}
        </Box>
      </Toolbar>
    </>
  );
};

export default ProcessHeader;
