export default {
  Fehling: {
    fehlingsA: 0.8085,
    fehlingsB: 0.355,
  },
  Neocuproin: {
    fehlingsA: 1,
    fehlingsB: 0,
  },
};
