import React, { ReactElement } from 'react';
import { Box, Typography, Divider } from '@material-ui/core';

type PageTitleProps = {
  title: string;
  subTitle?: string;
};

const PageTitle = ({ title, subTitle }: PageTitleProps): ReactElement => {
  return (
    <>
      <Box pt={6}>
        <Typography variant="h1">{title}</Typography>
        {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
      </Box>
      <Box mt={4} mb={3}>
        <Divider />
      </Box>
    </>
  );
};

export default PageTitle;
