import roundNumber from 'utils/roundNumber';

export type CalcIonExchResinSavingsInput = {
  numberOfSavedEqToCation: number;
  numberOfSavedEqToAnion: number;
  resinCationIonExchCost: number;
  resinAnionIonExchCost: number;
};

const calcIonExchResinSavings = ({
  numberOfSavedEqToCation,
  numberOfSavedEqToAnion,
  resinCationIonExchCost,
  resinAnionIonExchCost,
}: CalcIonExchResinSavingsInput): number => {
  const nonRoundedNumber = numberOfSavedEqToCation * resinCationIonExchCost + numberOfSavedEqToAnion * resinAnionIonExchCost;
  return roundNumber(nonRoundedNumber, 1);
};

export type CalcIonExchChemicalSavingsInput = {
  numberOfSavedEqToCation: number;
  numberOfSavedEqToAnion: number;
  chemicalCationIonExchCost: number;
  chemicalAnionIonExchCost: number;
};

const calcIonExchChemicalSavings = ({
  numberOfSavedEqToCation,
  numberOfSavedEqToAnion,
  chemicalCationIonExchCost,
  chemicalAnionIonExchCost,
}: CalcIonExchChemicalSavingsInput): number => {
  const nonRoundedNumber =
    numberOfSavedEqToCation * chemicalCationIonExchCost + numberOfSavedEqToAnion * chemicalAnionIonExchCost;
  return roundNumber(nonRoundedNumber, 1);
};

export type CalcIonExchWasteWaterSavingsInput = {
  numberOfSavedEqToCation: number;
  numberOfSavedEqToAnion: number;
  wasteWaterCationIonExchCost: number;
  wasteWaterAnionIonExchCost: number;
};

const calcIonExchWasteWaterSavings = ({
  numberOfSavedEqToCation,
  numberOfSavedEqToAnion,
  wasteWaterCationIonExchCost,
  wasteWaterAnionIonExchCost,
}: CalcIonExchWasteWaterSavingsInput): number => {
  const nonRoundedNumber =
    numberOfSavedEqToCation * wasteWaterCationIonExchCost + numberOfSavedEqToAnion * wasteWaterAnionIonExchCost;
  return roundNumber(nonRoundedNumber, 1);
};

export type CalcIonExchWaterSavingsInput = {
  numberOfSavedEqToCation: number;
  numberOfSavedEqToAnion: number;
  waterCationIonExchCost: number;
  waterAnionIonExchCost: number;
};

const calcIonExchWaterSavings = ({
  numberOfSavedEqToCation,
  numberOfSavedEqToAnion,
  waterCationIonExchCost,
  waterAnionIonExchCost,
}: CalcIonExchWaterSavingsInput): number => {
  const nonRoundedNumber = numberOfSavedEqToCation * waterCationIonExchCost + numberOfSavedEqToAnion * waterAnionIonExchCost;
  return roundNumber(nonRoundedNumber, 1);
};

export type CalcIonExchSweetWaterSavingsInput = {
  numberOfSavedEqToCation: number;
  numberOfSavedEqToAnion: number;
  sweetWaterCationIonExchCost: number;
  sweetWaterAnionIonExchCost: number;
};

const calcIonExchSweetWaterSavings = ({
  numberOfSavedEqToCation,
  numberOfSavedEqToAnion,
  sweetWaterCationIonExchCost,
  sweetWaterAnionIonExchCost,
}: CalcIonExchSweetWaterSavingsInput): number => {
  const nonRoundedNumber =
    numberOfSavedEqToCation * sweetWaterCationIonExchCost + numberOfSavedEqToAnion * sweetWaterAnionIonExchCost;
  return roundNumber(nonRoundedNumber, 1);
};

export default {
  calcIonExchResinSavings,
  calcIonExchChemicalSavings,
  calcIonExchWasteWaterSavings,
  calcIonExchWaterSavings,
  calcIonExchSweetWaterSavings,
};
