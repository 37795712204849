import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Amplify } from '@aws-amplify/core';
import ScenarioGetter from 'scenarios/ScenarioGetter';
import Page from 'components/layout/Page';
import Home from 'pages/Home';
import SetReference from 'pages/SetReference';
import IonExchange from 'pages/IonExchange';
import Savings from 'pages/Savings';
import amplifyConfig from 'amplifyConfig';
import DocumentView from 'components/DocumentView';
import { initializeCookieConsent, initializeGTM } from '@novozymes-digital/utils';
import ErrorBoundary from 'components/ErrorBoundary';
import { AuthEvent, validateState } from '@novozymes-digital/auth';
import Redirecter from 'Redirecter';
import TagManager from 'react-gtm-module';
import Cookies from 'pages/Cookies';
import Privacy from 'pages/Privacy';
import TermsConditions from 'pages/TermsConditions';
import Legal from 'pages/Legal';
import { getUserType } from 'utils/getUser';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'authentication-wrapper': any;
    }
  }
}

Amplify.configure(amplifyConfig);

const App = (): ReactElement => {
  const [userType, setUserType] = useState('not-logged-in');

  useEffect(() => {
    // Load cookie consent
    initializeCookieConsent();

    initializeGTM('GTM-KTWCZXC');
  }, []);

  const [authLoaded, setAuthLoaded] = useState(false);
  const [authStateReturnURL, setAuthStateReturnURL] = useState('');

  const onAuthEvent = useCallback(async (event) => {
    const authEvent = event as AuthEvent;
    if (authEvent.detail.newAuthState === 'signedIn') {
      setAuthLoaded(true);

      const currentUserType = await getUserType();
      setUserType(currentUserType);
    }
  }, []);
  // eslint-disable-next-line no-undef
  window.addEventListener('AuthStateChange', onAuthEvent);

  useEffect(() => {
    const goTOUrl = validateState();
    if (goTOUrl) {
      setAuthStateReturnURL(goTOUrl);
    }
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_type: userType,
      },
    });
  }, [userType]);

  return (
    <authentication-wrapper id="authentication" amplifyConfig={JSON.stringify(amplifyConfig)}>
      {authLoaded && authStateReturnURL ? (
        <RecoilRoot>
          <Router>
            <ErrorBoundary>
              <ScenarioGetter>
                <Redirecter redirectTo={authStateReturnURL}>
                  <Switch>
                    <Route path="/savings/:scenarioId?">
                      <Page>
                        <Savings />
                      </Page>
                    </Route>
                    <Route path="/ion-exchange/:scenarioId?">
                      <Page>
                        <IonExchange />
                      </Page>
                    </Route>
                    <Route path="/set-reference/:scenarioId?">
                      <Page>
                        <SetReference />
                      </Page>
                    </Route>
                    <Route exact path="/cookies">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Cookies />
                      </Page>
                    </Route>
                    <Route exact path="/privacy">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Privacy />
                      </Page>
                    </Route>
                    <Route exact path="/terms">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <TermsConditions />
                      </Page>
                    </Route>
                    <Route exact path="/legal">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Legal />
                      </Page>
                    </Route>
                    <Route exact path="/docs/:name">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <DocumentView />
                      </Page>
                    </Route>
                    <Route exact path="/:scenarioId?">
                      <Page hideProcessHeader hideFooter isHomePage>
                        <Home />
                      </Page>
                    </Route>
                  </Switch>
                </Redirecter>
              </ScenarioGetter>
            </ErrorBoundary>
          </Router>
        </RecoilRoot>
      ) : null}
    </authentication-wrapper>
  );
};

export default App;
