import roundNumber from 'utils/roundNumber';

export type CalcResinCostInput = {
  resinPrice: number;
  resinExchangeCapacity: number;
  resinLifetime: number;
};

export default ({ resinPrice, resinExchangeCapacity, resinLifetime }: CalcResinCostInput): number => {
  const nonRoundedValue = resinPrice / (resinExchangeCapacity * resinLifetime);
  return roundNumber(nonRoundedValue, 4);
};
