import React, { ReactElement } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import RegenerationCosts from './RegenerationCosts';
import ReductionInLoadFromLiq from './ReductionInLoadFromLiq';

const DetailedSavingsOnIX = (): ReactElement => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="h5">Regeneration costs</Typography>

      <RegenerationCosts />
    </Grid>
    <Grid item xs={6}>
      <Box pl={1}>
        <Typography variant="h5">Reduction in IX load from liquefaction</Typography>
      </Box>
      <ReductionInLoadFromLiq />
    </Grid>
  </Grid>
);

export default DetailedSavingsOnIX;
