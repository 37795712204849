import React, { ReactElement } from 'react';
import ReactPDF, { Text, View, StyleSheet } from '@react-pdf/renderer';
import roundNumber from 'utils/roundNumber';

const HIGHLIGHT_COLOR = '#EFF4BA';
const DEFAULT_BACKGROUND = '#eee';

const useStyle = (highlight?: boolean): ReactPDF.Styles => {
  const backgroundColor = highlight ? HIGHLIGHT_COLOR : DEFAULT_BACKGROUND;

  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px',
      borderRadius: 8,
      width: '100%',

      backgroundColor,
    },
    value: {
      minHeight: '12px',
      fontWeight: 'bold',
    },
    unit: {},
  });
};

const PDFValueHighlight = ({
  value,
  unit,
  highlight,
  decimals = 2,
}: {
  value?: number | string;
  unit?: string;
  highlight?: boolean;
  decimals?: number;
}): ReactElement => {
  const styles = useStyle(highlight);

  const formatedValue = typeof value === 'number' ? `${roundNumber(value, decimals)}` : `${value}`;

  return (
    <View style={styles.wrapper}>
      <View style={styles.value}>
        <Text>{formatedValue}</Text>
      </View>
      {unit && (
        <View style={styles.unit}>
          <Text>{`${unit}`}</Text>
        </View>
      )}
    </View>
  );
};

export default PDFValueHighlight;
