import defaultConc from 'scenarios/defaultConc';
import getDefaultPrices from 'scenarios/defaultPrices';
import { Scenario, ScenarioDataKey } from 'scenarios/ScenarioType';

export type UpdateScenario = (activeScenario: Scenario) => Scenario;

export type ScenarioDataUpdater = Partial<Record<ScenarioDataKey, UpdateScenario>>;

const catRegChem: UpdateScenario = (activeScenario) => {
  const defaultPrices = getDefaultPrices(activeScenario.region);
  const value = activeScenario.data.catRegChem;

  if (value === undefined) return activeScenario;

  return {
    ...activeScenario,
    data: { ...activeScenario.data, catChemConcentration: defaultConc[value], catChemPrice: defaultPrices[value] },
  };
};

const anRegChem: UpdateScenario = (activeScenario) => {
  const defaultPrices = getDefaultPrices(activeScenario.region);
  const value = activeScenario.data.anRegChem;

  if (value === undefined) return activeScenario;

  return {
    ...activeScenario,
    data: { ...activeScenario.data, anChemConcentration: defaultConc[value], anChemPrice: defaultPrices[value] },
  };
};

const baseChemicalName: UpdateScenario = (activeScenario) => {
  const defaultPrices = getDefaultPrices(activeScenario.region);
  const value = activeScenario.data.baseChemicalName;

  if (value === undefined) return activeScenario;

  return {
    ...activeScenario,
    data: {
      ...activeScenario.data,
      baseChemicalConcentration: defaultConc[value],
      baseChemicalPrice: defaultPrices[value],
    },
  };
};

const acidChemicalName: UpdateScenario = (activeScenario) => {
  const defaultPrices = getDefaultPrices(activeScenario.region);
  const value = activeScenario.data.acidChemicalName;

  if (value === undefined) return activeScenario;

  return {
    ...activeScenario,
    data: {
      ...activeScenario.data,
      acidChemicalConcentration: defaultConc[value],
      acidChemicalPrice: defaultPrices[value],
    },
  };
};

const calciumSourceName: UpdateScenario = (activeScenario) => {
  const defaultPrices = getDefaultPrices(activeScenario.region);
  const value = activeScenario.data.calciumSourceName;

  if (value === undefined) return activeScenario;

  return {
    ...activeScenario,
    data: { ...activeScenario.data, calciumConcentration: defaultConc[value], calciumPrice: defaultPrices[value] },
  };
};

const updaters: ScenarioDataUpdater = {
  catRegChem,
  anRegChem,
  baseChemicalName,
  acidChemicalName,
  calciumSourceName,
};

const defaultUpdater: UpdateScenario = (activeScenario) => activeScenario;

function getScenarioDataUpdater(key: ScenarioDataKey): UpdateScenario {
  return Object.entries(updaters).find(([selectorKey]) => selectorKey === key)?.[1] || defaultUpdater;
}

export default getScenarioDataUpdater;
