import React, { ChangeEvent, useState, useCallback, useEffect, useContext, ReactElement } from 'react';
import { Modal, Box, IconButton, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import { OutlinedInputWithLabel, Button } from '@novozymes/components';
import CloseIcon from '@material-ui/icons/Close';
import { Scenario } from 'scenarios/ScenarioType';
import logger from 'utils/logger';
import API from 'API';
import { ScenarioFetchContext } from 'scenarios/ScenarioGetter';
import { useRecoilState } from 'recoil';
import { activeScenarioState } from 'state/atomState';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserName } from 'utils/getUser';

export interface SaveDialogProps {
  scenario: Scenario;
  show: boolean;
  onClose(): void;
}

const useStyles = makeStyles((theme) => ({
  saveModal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 4),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  saveModalControls: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

const SaveDialog = ({ show, onClose, scenario }: SaveDialogProps): ReactElement => {
  const [userSelectedTitle, setUserSelectedTitle] = useState(scenario.title);
  const [saveAsNew, setSaveAsNew] = useState(!scenario.id);
  const [activeScenario, setActiveScenario] = useRecoilState(activeScenarioState);
  const history = useHistory();
  const location = useLocation();
  const fetchScenario = useContext(ScenarioFetchContext);

  const classes = useStyles();

  const handleCheckboxClick = useCallback(() => {
    setSaveAsNew(!saveAsNew);
  }, [saveAsNew]);

  useEffect(() => {
    setUserSelectedTitle(scenario.title);
  }, [scenario]);

  const handleSaveSubmit = useCallback(async () => {
    const { data, id, region } = scenario;

    const userName = await getUserName();

    const body = { title: userSelectedTitle, scenarioData: data, region, id, creatorName: userName };

    const updateFunction = saveAsNew ? API.Scenarios.create : API.Scenarios.update;

    try {
      const response = await updateFunction({ body, id });
      if (saveAsNew) {
        const createData = response.data<{ statusCode: string; body: string }>();
        const page = location.pathname.split('/')[1];
        history.replace(`/${page}/${createData.body}`);
      } else {
        const updateData = response.data<{ statusCode: string; body: string }>();

        if (updateData.statusCode !== '200') {
          throw Error(`Scenario update unsuccessfull: ${updateData.body}`);
        } else {
          setActiveScenario({ ...scenario, title: userSelectedTitle });
          fetchScenario(id);
        }
      }
      onClose();
    } catch (err) {
      logger.error('Failed to save scenario' + err);
    }
  }, [scenario, activeScenario, userSelectedTitle, saveAsNew]);

  return (
    <Modal open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
      <Box className={classes.saveModal}>
        <Box className={classes.modalHeader}>
          <IconButton id="btn-save-dialog-close" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <OutlinedInputWithLabel
          type="text"
          key="scenarioTitle"
          label="Enter a scenario title"
          value={userSelectedTitle}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            setUserSelectedTitle(value);
          }}
        />
        <Box py={2} className={classes.saveModalControls}>
          {scenario.id ? (
            <FormControlLabel
              id="check-save-dialog-save-as-new"
              control={<Checkbox checked={saveAsNew} onChange={handleCheckboxClick} name="saveAsNew" color="primary" />}
              label="Save as new scenario"
            />
          ) : (
            <Box />
          )}
          <Button
            id="btn-save-dialog-save"
            type="primary"
            small
            onClick={handleSaveSubmit}
            disabled={userSelectedTitle.length <= 1}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveDialog;
