import { useParams } from 'react-router-dom';
import { ScenarioParams } from 'scenarios/ScenarioType';

export const PROCESS_STEPS = [
  {
    title: '1. Set reference',
    url: '/set-reference',
  },
  {
    title: '2. Plant capacity, liquefaction and IX',
    url: '/ion-exchange',
  },
  {
    title: '3. Your savings',
    url: '/savings',
  },
];

export const getRouteIndex = (currentRoute: string): number =>
  PROCESS_STEPS.findIndex((step) => currentRoute.startsWith(step.url));

export const getPrevRoute = (currentRoute: string): string | undefined => {
  const currentIndex = getRouteIndex(currentRoute);
  const params = useParams<ScenarioParams>();
  if (currentIndex === 0 || currentIndex === -1) {
    return undefined;
  }
  return `${PROCESS_STEPS[currentIndex - 1].url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
};

export const getCurrentRoute = (currentRoute: string): string | undefined => {
  const currentIndex = getRouteIndex(currentRoute);
  const params = useParams<ScenarioParams>();
  if (currentIndex === -1) {
    return undefined;
  }
  return `${PROCESS_STEPS[currentIndex].url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
};

export const getNextRoute = (currentRoute: string): string | undefined => {
  const currentIndex = getRouteIndex(currentRoute);
  const params = useParams<ScenarioParams>();
  if (currentIndex === PROCESS_STEPS.length - 1) {
    return undefined;
  }
  if (currentIndex === -1) {
    return `${PROCESS_STEPS[0].url}/${params.scenarioId ? `${params.scenarioId}` : ''}`;
  }
  return `${PROCESS_STEPS[currentIndex + 1].url}${params.scenarioId ? `/${params.scenarioId}` : ''}`;
};
export default {};
