import React, { ReactElement } from 'react';
import { Grid, Typography, Box, Fade } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import SavingsChartWrapper from 'components/SavingsChartWrapper';
import { SavingsChartEntry } from 'components/SavingsChart';

import ValueHighlight from 'components/ValueHighlight';

import {
  ixChemicalSavings,
  liqEnzymeSavings,
  liqCalciumSavings,
  liqBaseSavings,
  liqAcidSavings,
  ionExchangeResinSavings,
  ionExchWasteWaterSavings,
  ionExchWaterSavings,
  ionExchSweetWaterSavings,
  dailyTotalsWithEnzymesState,
  currency,
  dailyTotalsWithoutEnzymesState,
  areEnzymePricesGivenState,
} from 'state/getDerivedValues';

import getAtomState from 'state/atomState';
import useInputWithState from 'state/useInputWithState';
import DividerWithSpacing from 'components/DividerWithSpacing';
import Placeholder from 'components/Placeholder';

const Savings = (): ReactElement => {
  const pricesGiven = useRecoilValue(areEnzymePricesGivenState);

  const newEnzymeState = getAtomState<string>('newEnzymeName');
  const refEnzymeState = getAtomState<string>('refEnzymeName');
  const newEnzyme = useRecoilValue(newEnzymeState);
  const refEnzyme = useRecoilValue(refEnzymeState);

  const refEnzymeDosage = useRecoilValue(getAtomState<number>('refEnzymeDosage'));
  const newEnzymeDosage = useRecoilValue(getAtomState<number>('newEnzymeDosage'));

  const refEnzymePrice = useRecoilValue(getAtomState<number>('refEnzymePrice'));
  const newEnzymePrice = useRecoilValue(getAtomState<number>('newEnzymePrice'));

  const plantCapacityState = getAtomState<number>('plantCapacity');
  const plantCapacity = useRecoilValue(plantCapacityState);

  const dailyTotalsValueNoEnzyme = Math.round(useRecoilValue(dailyTotalsWithoutEnzymesState));
  const yearlySavingsNoEnzyme = dailyTotalsValueNoEnzyme * 365;
  const savingsPerStarchNoEnzyme = plantCapacity ? dailyTotalsValueNoEnzyme / plantCapacity : 0;

  const dailyTotalsValue = Math.round(useRecoilValue(dailyTotalsWithEnzymesState));
  const yearlySavings = dailyTotalsValue * 365;
  const savingsPerStarch = plantCapacity ? dailyTotalsValue / plantCapacity : 0;

  const savingsData: SavingsChartEntry[] = [
    { savingType: 'Total savings Σ', savings: dailyTotalsValue, disable: !pricesGiven, highlight: true },
    { savingType: 'Liquefaction enzyme', savings: useRecoilValue(liqEnzymeSavings), disable: !pricesGiven },
    { savingType: 'Calcium added', savings: useRecoilValue(liqCalciumSavings) },
    { savingType: 'Liquefaction base', savings: useRecoilValue(liqBaseSavings) },
    { savingType: 'Liquefaction acid', savings: useRecoilValue(liqAcidSavings) },
    { savingType: 'IX resins', savings: useRecoilValue(ionExchangeResinSavings) },
    { savingType: 'IX chemicals', savings: useRecoilValue(ixChemicalSavings) },
    { savingType: 'IX Waste water', savings: useRecoilValue(ionExchWasteWaterSavings) },
    { savingType: 'IX water', savings: useRecoilValue(ionExchWaterSavings) },
    { savingType: 'IX SW evaporation', savings: useRecoilValue(ionExchSweetWaterSavings) },
  ];

  const activeCurrency = useRecoilValue(currency);

  const [, newEnzymePriceInput] = useInputWithState({
    key: 'newEnzymePrice',
    unit: `${activeCurrency} / kg`,
  });

  const [, refEnzymePriceInput] = useInputWithState({
    key: 'refEnzymePrice',
    unit: `${activeCurrency} / kg`,
  });

  const refEnzymePriceForDose = (refEnzymeDosage || 0) * (refEnzymePrice || 0);
  const newEnzymePriceForDose = (newEnzymeDosage || 0) * (newEnzymePrice || 0);

  return (
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Savings</b> without enzyme cost
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <ValueHighlight value={yearlySavingsNoEnzyme} unit={`${activeCurrency} / year`} highlight />
          </Grid>
          <Grid item xs={4}>
            <ValueHighlight value={dailyTotalsValueNoEnzyme} unit={`${activeCurrency} / day`} highlight />
          </Grid>

          <Grid item xs={4}>
            <ValueHighlight value={savingsPerStarchNoEnzyme} unit={`${activeCurrency} / tDS`} highlight />
          </Grid>
        </Grid>

        <DividerWithSpacing pt={5} pb={3} />

        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body1">{`Price of reference - ${refEnzyme}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            {refEnzymePriceInput}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body1">{`Price of new enzyme - ${newEnzyme}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            {newEnzymePriceInput}
          </Grid>
        </Grid>

        <DividerWithSpacing pt={3} pb={4} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Total savings</b> including enzyme cost
            </Typography>
          </Grid>

          {!pricesGiven && (
            <Grid item xs={12}>
              <Placeholder>
                <Typography variant="body1">- Provide price details for total savings -</Typography>
              </Placeholder>
            </Grid>
          )}
        </Grid>

        <Fade in={pricesGiven}>
          <Box mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ValueHighlight value={yearlySavings} unit={`${activeCurrency} / year`} highlight={pricesGiven} large />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={dailyTotalsValue} unit={`${activeCurrency} / day`} highlight={pricesGiven} large />
              </Grid>

              <Grid item xs={4}>
                <ValueHighlight value={savingsPerStarch} unit={`${activeCurrency} / tDS`} highlight={pricesGiven} large />
              </Grid>

              <Grid item xs={4}>
                <ValueHighlight value={plantCapacity} unit="tDS starch / day" highlight={pricesGiven} large />
              </Grid>
            </Grid>
          </Box>
        </Fade>

        <Fade in={pricesGiven}>
          <Box pt={5}>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Typography variant="body1">Reference</Typography>
                </Box>
                <ValueHighlight value={refEnzyme} />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={refEnzymeDosage} unit="kg / tDS" />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={refEnzymePriceForDose} unit={`${activeCurrency} / tDS`} />
              </Grid>

              <Grid item xs={4}>
                <Box mb={2}>
                  <Typography variant="body1">New solution</Typography>
                </Box>
                <ValueHighlight value={newEnzyme} />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={newEnzymeDosage} unit="kg / tDS" />
              </Grid>
              <Grid item xs={4}>
                <ValueHighlight value={newEnzymePriceForDose} unit={`${activeCurrency} / tDS`} />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Grid>
      <Grid item xs={6}>
        <Box pl={4}>
          <Typography variant="h4">Summary</Typography>
          <SavingsChartWrapper data={savingsData} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Savings;
