import DEMethods from './DEMethods';
import { CompetitorInput } from './EnzymeInput';

export default (input: CompetitorInput): number[] => {
  const simulation: number[] = Array(9);
  const { fehlingsA, fehlingsB } = input.DEMethod && DEMethods[input.DEMethod];
  if (input.competitorDEMethod === input.DEMethod) {
    for (let timeI = 0; timeI < simulation.length; timeI += 1) {
      const time = timeI * input.timeStep;
      simulation[timeI] = (time * input.liquefactionDE) / input.liquefactionTime;
    }
  } else if (input.competitorDEMethod === 'Fehling' && input.DEMethod === 'Neocuproin') {
    for (let timeI = 0; timeI < simulation.length; timeI += 1) {
      const time = timeI * input.timeStep;
      simulation[timeI] = -0.355 + (time * input.liquefactionDE) / input.liquefactionTime / 0.8085;
    }
  } else if (input.competitorDEMethod === 'Neocuproin' && input.DEMethod === 'Fehling') {
    for (let timeI = 0; timeI < simulation.length; timeI += 1) {
      const time = timeI * input.timeStep;
      simulation[timeI] = fehlingsB + fehlingsA * ((time * input.liquefactionDE) / input.liquefactionTime);
    }
  }
  return simulation;
};
