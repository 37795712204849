import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_TOKEN || '',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  sampleRate: 100,
  service: 'starch-liquefaction',
  env: process.env.REACT_APP_STAGE,
});

const logger = datadogLogs.createLogger('starch-liquefaction-logger', {
  context: {
    service: 'starch-liquefaction',
    env: process.env.REACT_APP_STAGE,
  },
});

export default logger;
