import React, { ReactElement } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import NumberFormat from 'react-number-format';
import isNaN from 'lodash/isNaN';
import { colors } from '@novozymes/components';

const HIGHLIGHT_COLOR = '#EFF4BA';

interface ValueHighlightProps {
  value?: number | string;
  unit?: string;
  highlight?: boolean;
  large?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: ({ large, highlight }: ValueHighlightProps): CreateCSSProperties => ({
    display: 'inline-flex',
    justifyContent: 'space-between',
    backgroundColor: highlight ? HIGHLIGHT_COLOR : colors.bg3,
    padding: large ? theme.spacing(1.5) : '10px 12px',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: `1px solid ${highlight ? HIGHLIGHT_COLOR : '#E6E6E6'}`,
  }),
  value: ({ large }: ValueHighlightProps): CreateCSSProperties => ({
    fontWeight: 'bold',
    fontSize: large ? '18px' : '1rem',
    lineHeight: large ? '22px' : '1.1876em',
    color: colors.black80,
  }),
  unit: ({ large }: ValueHighlightProps): CreateCSSProperties => ({
    fontSize: large ? '16px' : '1rem',
    lineHeight: large ? '24px' : '1.1876em',
  }),
}));

const ValueHighlight = ({ value, unit, highlight, large }: ValueHighlightProps): ReactElement => {
  const classes = useStyles({ highlight, large });

  const num = Number(value);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.value}>
        {!isNaN(num) ? (
          <NumberFormat displayType="text" value={num} thousandSeparator=" " decimalSeparator="." decimalScale={4} />
        ) : (
          value
        )}
      </Box>
      {unit && <Box className={classes.unit}>{unit}</Box>}
    </Box>
  );
};

export default ValueHighlight;
