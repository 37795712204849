import { Effects } from 'calculation/ionExchange/sweetWaterEvaporation';

const getSteamConsumption = (effects: Effects): number => {
  switch (effects) {
    case '1':
      return 0.5;
    case '2':
      return 0.33;
    case '3':
      return 0.25;
    case '4+':
      return 0.2;
    default:
      return 0;
  }
};

export default getSteamConsumption;
