import React, { ChangeEvent, ReactElement } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OutlinedInputWithLabel, SelectWithLabel } from '@novozymes/components';
import getAtomState, { scenarioErrorState } from 'state/atomState';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
  withStyles,
  Box,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ScenarioDataKey } from 'scenarios/ScenarioType';

type useInputWithStateProps<T> = {
  key: ScenarioDataKey;
  label?: string;
  type?: 'TextField' | 'Select' | 'Radio';
  selectOptions?: {
    value?: string | number;
    label: string;
  }[];
  helperText?: string;
  unit?: string;
};

const ColoredRadio = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.67)',
    '&$checked': {
      color: 'rgba(0, 0, 0, 0.67)',
    },
  },
  checked: {},
})((props: RadioProps) => <Radio size="small" color="default" {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  radioButtonHeading: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
  radioButtonLabel: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

function useInputWithState<T extends string | number | string[] | undefined>({
  key,
  label,
  type = 'TextField',
  selectOptions,
  helperText = '',
  unit,
}: useInputWithStateProps<T>): [T | undefined, ReactElement | null] {
  const state = getAtomState<T>(key);
  const [value, setValue] = useRecoilState(state);
  const errorState = useRecoilValue(scenarioErrorState);
  const error = errorState[key];
  const classes = useStyles();

  const getValueAsType = (changeValue: any): T => {
    switch (typeof value) {
      case 'number':
        return Number(changeValue) as T;

      default:
        return changeValue;
    }
  };

  const getInput = (): ReactElement | null => {
    if (type === 'TextField') {
      return (
        <OutlinedInputWithLabel
          key={label}
          label={label}
          value={value !== undefined ? (value as string | number) : ''}
          error={!!error}
          errorMessage={error}
          placeholder={helperText}
          adornment={unit}
          handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value: newValue } = event.target;
            setValue(newValue as unknown as T);
          }}
          formatInput
          decimalSeparator="."
        />
      );
    }
    if (type === 'Select' && selectOptions) {
      return (
        <SelectWithLabel
          value={helperText ? (value as string | number) : (value as string | number) || ' '}
          label={label}
          options={selectOptions}
          placeholder={helperText}
          error={!!error}
          errorMessage={error}
          handleChange={(
            event: ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ): void => {
            setValue(getValueAsType(event.target.value));
          }}
        />
      );
    }

    if (type === 'Radio' && selectOptions) {
      return (
        <FormControl component="fieldset">
          {label && <Box className={classes.radioButtonHeading}>{label}</Box>}
          <RadioGroup
            aria-label={label}
            name={label}
            value={value ? `${value}` : ' '}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setValue(getValueAsType(event.target.value));
            }}
          >
            {selectOptions.map((option) => (
              <FormControlLabel
                key={option.value as string}
                value={option.value}
                control={<ColoredRadio />}
                label={option.label}
                labelPlacement="end"
                classes={{
                  label: classes.radioButtonLabel,
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }
    return null;
  };

  const input = getInput();

  return [value, input];
}
export default useInputWithState;
